<template>
  <div class="uses">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <header>
        <p>Guidelines for Chinese third parties when using Arm's trademark</p>
        <h4>中国第三方使用Arm的商标之准则</h4>
      </header>
      <main class="clearfix">
        <div class="left" :style="lstyle">
          <div class="one_box">
            <p>These guidelines are provided by Arm Limited ("Arm") to educate third parties located in the mainland of the People's Republic of China, the Hong Kong Special Administrative Region of the People's Republic of China and the Macao Special Administrative Region of the People's Republic of China (including without limitation any licensee, partner, organisation or other entity associated with, or invested in, by Arm Technology (China) Co. Ltd. ("Arm China") in relation to the use of Arm's trademarks. Arm may update these guidelines at any time.</p>
            <p>For the avoidance of doubt, "other entity associated with, or invested in, by Arm China" includes subsidiary companies, directors and shareholders of such entities.</p>
            <p>
              Arm's trademarks are among Arm's most valuable intellectual property assets. They represent the highest standards of quality and excellence associated with Arm's products and services. Arm's trademarks include without limitation the "Arm" word and corporate logo, the word trademarks listed on Arm's website <a href="https://www.arm.com/company/policies/trademarks/arm-trademark-list"> <span>here</span> </a> and Chinese transliteration marks, including 安谋 (AN MOU) and 芯础 (XIN CHU). Further information regarding Arm's trademarks and their use can be found <a href="https://www.arm.com/company/policies/trademarks"><span>here</span></a>
            </p>
            <p>Arm China is permitted by Arm to use Arm's trademarks to promote Arm's products and services in the mainland of the People's Republic of China, the Hong Kong Special Administrative Region of the People's Republic of China and the Macao Special Administrative Region of the People's Republic of China. Arm China is permitted to sub-licence Arm's trademarks to licensees in the same territories for this purpose. Arm China is also permitted by Arm to use the "Arm" word mark and the corresponding Chinese transliteration mark "安谋" （AN MOU）as part of the Arm China company name, trade name and armchina.com domain name. Arm China is not licensed by Arm to sub-license these rights to any third parties to use in their company names, trade names, domains or other names.</p>
            <p>Any third party sub-licensed by Arm China in relation to Arm's trademarks must follow any applicable trademark provisions and guidelines set out in their licence agreement(s).</p>
            <p>Unless licensed or otherwise permitted by Arm (either directly or indirectly through Arm China), third parties located in the Chinese territories above must NOT:</p>
          </div>
          <ul>
            <li class="clearfix">
              <div>——</div>
              <p>use or register any trademark that is identical or similar to any Arm trademark or takes unfair advantage of the reputation of any Arm trademark. Here, "similar" includes (without limitation) marks that are phonetically and/or visually similar to Arm's trademarks, or marks that incorporate Arm's trademarks, e.g. ArmChip;</p>
            </li>
            <li class="clearfix">
              <div>——</div>
              <p>use or register (as a trademark) any product name, service name or technology name that is identical or similar to an Arm trademark and/or product name, service name or technology name, e.g. Cortex-M0 or M0;</p>
            </li>
            <li class="clearfix">
              <div>——</div>
              <p>
                use any Arm trademark in relation to any company name, trade name, organisation or association, e.g. Beijing Arm Technology Co. Ltd.;
              </p>
            </li>
            <li class="clearfix">
              <div>——</div>
              <p>use or register any domain name containing an Arm trademark, product name, service name or technology name e.g. armecosystem.cn or armcortex-m0.com; and</p>
            </li>
            <li class="clearfix">
              <div>——</div>
              <p>use or register any social media name containing an Arm trademark, product name, service name or technology name, e.g. @ArmPartner.</p>
            </li>
          </ul>
          <div class="tow_box">
            <p>Any third party found to have committed any of the prohibited acts above may be requested by Arm (and/or Arm China) to transfer any relevant intellectual property rights assets (including without limitation any trademark registration, pending trademark application and domain name) to Arm. Arm may request any third party using any Arm trademark, product name, service name or technology name without permission to remove them from their company name, business name, social media name, product name, service name, technology name and/or other relevant name.</p>
            <p>
              Third parties located in the Chinese territories above may use Arm's word trademarks to refer to Arm's products and services and related technology only if they follow <a href="https://www.arm.com/company/policies/trademarks"><span>Arm's Trademark Usage Guidelines</span></a
              >and their use is accurate, fair and not misleading.
            </p>
            <p>
              Please contact Arm's Trademark Team regarding any questions concerning the use of <a href="https://www.arm.com/company/policies/trademarks/enquiry-form"><span>Arm's trademarks</span></a>
            </p>
          </div>
        </div>
        <div class="right" :style="rstyle">
          <div class="three_box">
            <p>本准则由Arm Limited（以下简称“Arm”）制定，用以指导位于中华人民共和国大陆地区、中华人民共和国香港特别行政区和中华人民共和国澳门特别行政区的第三方（包括但不限于任何被许可人、合作伙伴、组织或其他与安谋科技（中国）有限公司（以下简称“安谋科技”）相关联或有投资关系的实体）正确使用Arm的商标。Arm可能随时修订本准则。</p>
            <p>为避免疑义，“与安谋科技相关联或有投资关系的实体”包括这些实体的子公司、董事和股东。</p>
            <p>
              Arm的商标是Arm最有价值的知识产权资产之一。它们代表了Arm的产品和服务的最高质量标准和卓越本质。Arm的商标包括但不限于“ Arm”字样和公司徽标、在Arm网站上列出的所有文字商标 <a href="https://www.arm.com/company/policies/trademarks/arm-trademark-list"><span>参见此处</span></a
              >以及中文音译商标，包括“安谋”（AN MOU）和“芯础”（XIN CHU）。欲知有关Arm的商标及其使用的更多信息，请 <a href="https://www.arm.com/company/policies/trademarks"><span>参见此处</span></a
              >。
            </p>
            <p>Arm允许安谋科技在中华人民共和国大陆地区、中华人民共和国香港特别行政区和中华人民共和国澳门特别行政区使用Arm的商标推广Arm的产品和服务。基于此目的，安谋科技可以将Arm的商标再许可给位于上述地区的被许可人。Arm允许安谋科技 “Arm”文字商标以及对应中文音译商标 “安谋”（AN MOU）用作安谋科技的公司名称、商号和armchina.com域名的一部分，但并未许可安谋科技将这些权利再许可给任何第三方在其公司名称、商号、域名或其他名称中使用。</p>
            <p>由安谋科技再许可的与Arm的商标相关的任何第三方都必须遵守其许可协议中规定的所有适用的商标规定和准则。</p>
            <p>除非经Arm（直接或通过安谋科技间接）许可或以其他方式允许，否则位于上述中国地区内的第三方不得：</p>
          </div>
          <ul>
            <li class="clearfix">
              <div>——</div>
              <p>使用或注册与Arm的任何商标相同或相似的商标，或不正当地利用Arm的任何商标的声誉。 在此，“相似”包括（但不限于）在语音和/或视觉上与Arm的商标相似的商标，或包含Arm商标的商标，例如 ArmChip；</p>
            </li>
            <li class="clearfix">
              <div>——</div>
              <p>使用或（作为商标）注册与Arm商标和/或产品名称、服务名称或技术名称相同或相似的任何产品名称、服务名称或技术名称，例如 Cortex-M0或M0；</p>
            </li>
            <li class="clearfix">
              <div>——</div>
              <p>将Arm的任何商标用于任何公司名称、商号、组织或协会名称，例如 北京安谋科技有限公司；</p>
            </li>
            <li class="clearfix">
              <div>——</div>
              <p>使用或注册任何包含Arm的商标、产品名称、服务名称或技术名称的域名，例如 armecosystem.cn或armcortex-m0.com；以及</p>
            </li>
            <li class="clearfix">
              <div>——</div>
              <p>使用或注册任何包含Arm的商标、产品名称、服务名称或技术名称的社交媒体名称，例如 @ArmPartner。</p>
            </li>
          </ul>
          <div class="four_box">
            <p>被发现犯有上述任何违禁行为的任何第三方将被Arm（和/或安谋科技）要求将全部相关的知识产权资产（包括但不限于任何商标注册、商标申请和域名）转让给Arm 。Arm将要求未经允许使用Arm的商标、产品名称、服务名称或技术名称的任何第三方将相关内容从其公司名称、企业名称、社交媒体名称、产品名称、服务名称、技术名称和/或其他相关名称中删除。</p>
            <p>
              位于上述中国地区的第三方只有在遵守<a href="https://www.arm.com/company/policies/trademarks"><span>《Arm商标使用准则》</span></a
              >并且以准确、正当且不会引起误解的方式使用的前提下，才可以用Arm的文字商标来代表Arm的产品、服务和相关技术。
            </p>
            <p>
              如有任何关于Arm的商标使用的疑问，请联系<a href="https://www.arm.com/company/policies/trademarks/enquiry-form"><span>Arm商标组</span></a>
            </p>
          </div>
        </div>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <webNav />
      <header>
        <p>Guidelines for Chinese third parties when using Arm's trademarks</p>
        <h4>中国第三方使用Arm的商标之准则</h4>
      </header>
      <main>
        <div class="one_box">
          <p>These guidelines are provided by Arm Limited ("Arm") to educate third parties located in the mainland of the People's Republic of China, the Hong Kong Special Administrative Region of the People's Republic of China and the Macao Special Administrative Region of the People's Republic of China (including without limitation any licensee, partner, organisation or other entity associated with, or invested in, by Arm Technology (China) Co. Ltd. ("Arm China") in relation to the use of Arm's trademarks. Arm may update these guidelines at any time.</p>
          <p>For the avoidance of doubt, "other entity associated with, or invested in, by Arm China" includes subsidiary companies, directors and shareholders of such entities.</p>
          <p>Arm's trademarks are among Arm's most valuable intellectual property assets. They represent the highest standards of quality and excellence associated with Arm's products and services. Arm's trademarks include without limitation the "Arm" word and corporate logo, the word trademarks listed on Arm's website <span>here</span> and Chinese transliteration marks, including 安谋 (AN MOU) and 芯础 (XIN CHU). Further information regarding Arm's trademarks and their use can be found <span>here</span></p>
          <p>Arm China is permitted by Arm to use Arm's trademarks to promote Arm's products and services in the mainland of the People's Republic of China, the Hong Kong Special Administrative Region of the People's Republic of China and the Macao Special Administrative Region of the People's Republic of China. Arm China is permitted to sub-licence Arm's trademarks to licensees in the same territories for this purpose. Arm China is also permitted by Arm to use the "Arm" word mark and the corresponding Chinese transliteration mark "安谋" （AN MOU）as part of the Arm China company name, trade name and armchina.com domain name. Arm China is not licensed by Arm to sub-license these rights to any third parties to use in their company names, trade names, domains or other names.</p>
          <p>Any third party sub-licensed by Arm China in relation to Arm's trademarks must follow any applicable trademark provisions and guidelines set out in their licence agreement(s).</p>
          <p>Unless licensed or otherwise permitted by Arm (either directly or indirectly through Arm China), third parties located in the Chinese territories above must NOT:</p>
        </div>
        <ul>
          <li class="clearfix">
            <div>——</div>
            <p>use or register any trademark that is identical or similar to any Arm trademark or takes unfair advantage of the reputation of any Arm trademark. Here, "similar" includes (without limitation) marks that are phonetically and/or visually similar to Arm's trademarks, or marks that incorporate Arm's trademarks, e.g. ArmChip;</p>
          </li>
          <li class="clearfix">
            <div>——</div>
            <p>use or register (as a trademark) any product name, service name or technology name that is identical or similar to an Arm trademark and/or product name, service name or technology name, e.g. Cortex-M0 or M0;</p>
          </li>
          <li class="clearfix">
            <div>——</div>
            <p>
              use any Arm trademark in relation to any company name, trade name, organisation or association, e.g. Beijing Arm Technology Co. Ltd.;
            </p>
          </li>
          <li class="clearfix">
            <div>——</div>
            <p>use or register any domain name containing an Arm trademark, product name, service name or technology name e.g. armecosystem.cn or armcortex-m0.com; and</p>
          </li>
          <li class="clearfix">
            <div>——</div>
            <p>use or register any social media name containing an Arm trademark, product name, service name or technology name, e.g. @ArmPartner.</p>
          </li>
        </ul>
        <div class="tow_box">
          <p>Any third party found to have committed any of the prohibited acts above may be requested by Arm (and/or Arm China) to transfer any relevant intellectual property rights assets (including without limitation any trademark registration, pending trademark application and domain name) to Arm. Arm may request any third party using any Arm trademark, product name, service name or technology name without permission to remove them from their company name, business name, social media name, product name, service name, technology name and/or other relevant name.</p>
          <p>Third parties located in the Chinese territories above may use Arm's word trademarks to refer to Arm's products and services and related technology only if they follow <span>Arm's Trademark Usage Guidelines</span> and their use is accurate, fair and not misleading.</p>
          <p>Please contact Arm's Trademark Team regarding any questions concerning the use of <span>Arm's trademarks</span></p>
        </div>
        <div class="three_box">
          <p>本准则由Arm Limited（以下简称“Arm”）制定，用以指导位于中华人民共和国大陆地区、中华人民共和国香港特别行政区和中华人民共和国澳门特别行政区的第三方（包括但不限于任何被许可人、合作伙伴、组织或其他与安谋科技（中国）有限公司（以下简称“安谋科技”）相关联或有投资关系的实体）正确使用Arm的商标。Arm可能随时修订本准则。</p>
          <p>为避免疑义，“与安谋科技相关联或有投资关系的实体”包括这些实体的子公司、董事和股东。</p>
          <p>Arm的商标是Arm最有价值的知识产权资产之一。它们代表了Arm的产品和服务的最高质量标准和卓越本质。Arm的商标包括但不限于“ Arm”字样和公司徽标、在Arm网站上列出的所有文字商标<span>参见此处</span>以及中文音译商标，包括“安谋”（AN MOU）和“芯础”（XIN CHU）。欲知有关Arm的商标及其使用的更多信息，请 <span>参见此处</span>。</p>
          <p>Arm允许安谋科技在中华人民共和国大陆地区、中华人民共和国香港特别行政区和中华人民共和国澳门特别行政区使用Arm的商标推广Arm的产品和服务。基于此目的，安谋科技可以将Arm的商标再许可给位于上述地区的被许可人。Arm允许安谋科技 “Arm”文字商标以及对应中文音译商标 “安谋”（AN MOU）用作安谋科技的公司名称、商号和armchina.com域名的一部分，但并未许可安谋科技将这些权利再许可给任何第三方在其公司名称、商号、域名或其他名称中使用。</p>
          <p>由安谋科技再许可的与Arm的商标相关的任何第三方都必须遵守其许可协议中规定的所有适用的商标规定和准则。</p>
          <p>除非经Arm（直接或通过安谋科技间接）许可或以其他方式允许，否则位于上述中国地区内的第三方不得：</p>
        </div>
        <ul>
          <li class="clearfix">
            <div>——</div>
            <p>使用或注册与Arm的任何商标相同或相似的商标，或不正当地利用Arm的任何商标的声誉。 在此，“相似”包括（但不限于）在语音和/或视觉上与Arm的商标相似的商标，或包含Arm商标的商标，例如 ArmChip；</p>
          </li>
          <li class="clearfix">
            <div>——</div>
            <p>使用或（作为商标）注册与Arm商标和/或产品名称、服务名称或技术名称相同或相似的任何产品名称、服务名称或技术名称，例如 Cortex-M0或M0；</p>
          </li>
          <li class="clearfix">
            <div>——</div>
            <p>将Arm的任何商标用于任何公司名称、商号、组织或协会名称，例如 北京安谋科技有限公司；</p>
          </li>
          <li class="clearfix">
            <div>——</div>
            <p>使用或注册任何包含Arm的商标、产品名称、服务名称或技术名称的域名，例如 armecosystem.cn或armcortex-m0.com；以及</p>
          </li>
          <li class="clearfix">
            <div>——</div>
            <p>使用或注册任何包含Arm的商标、产品名称、服务名称或技术名称的社交媒体名称，例如 @ArmPartner。</p>
          </li>
        </ul>
        <div class="four_box">
          <p>被发现犯有上述任何违禁行为的任何第三方将被Arm（和/或安谋科技）要求将全部相关的知识产权资产（包括但不限于任何商标注册、商标申请和域名）转让给Arm 。Arm将要求未经允许使用Arm的商标、产品名称、服务名称或技术名称的任何第三方将相关内容从其公司名称、企业名称、社交媒体名称、产品名称、服务名称、技术名称和/或其他相关名称中删除。</p>
          <p>位于上述中国地区的第三方只有在遵守<span>《Arm商标使用准则》</span>并且以准确、正当且不会引起误解的方式使用的前提下，才可以用Arm的文字商标来代表Arm的产品、服务和相关技术。</p>
          <p>如有任何关于Arm的商标使用的疑问，请联系<span>Arm商标组</span></p>
        </div>
      </main>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: undefined,
      equipment: '',
      only: true,
      mask: 'NO',
      showFooter: true,
      lstyle: '',
      rstyle: '',
    }
  },
  created() {
    document.title = 'Arm商标使用规范 - 安谋科技'
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
      this.lstyle = ''
      this.rstyle = ''
    } else {
      this.equipment = 'pc'

      this.lstyle = 'float: left;width: 7.96rem; '
      this.rstyle = 'float: right;width: 8.52rem;'
    }
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        this.h = document.documentElement.clientHeight // 视口的高度
        if (this.w <= 750) {
          this.equipment = 'web'
          this.lstyle = ''
          this.rstyle = ''
        } else {
          this.equipment = 'pc'
          this.lstyle = 'float: left;width: 7.96rem; '
          this.rstyle = 'float: right;width: 8.52rem;'
        }
      })()
    }
  },
}
</script>

<style lang="less" scoped>
.uses {
  background: #000;
  .pc {
    header {
      height: 350px;
      padding-top: 158px;
      color: #fff;
      p {
        text-align: center;
        font-size: 0.33rem /* 33/100 */;
        margin-top: 1rem /* 150/100 */;
      }
      h4 {
        font-size: 0.46rem /* 46/100 */;
        text-align: center;
        margin-top: 0.1rem /* 10/100 */;
      }
    }
    main {
      padding-top: 1.08rem /* 108/100 */;
      padding-right: 0.79rem /* 134/100 */;
      padding-left: 1.34rem /* 79/100 */;
      .left {
        height: 100%;
        p {
          font-size: 14px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #fff;
          line-height: 27px;
          margin-bottom: 40px;
          span {
            color: #00b3d6;
          }
        }
        ul {
          margin-bottom: 10px;
          li {
            div {
              float: left;
              line-height: 27px;
              color: #fff;
            }
            p {
              width: 7.45rem /* 740/100 */;
              float: right;
            }
          }
        }
        .one_box {
          margin-bottom: 54px;
        }
        .tow_box {
          margin-bottom: 150px;
        }
      }
      .right {
        height: 100%;
        p {
          font-size: 14px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #fff;
          line-height: 27px;
          margin-bottom: 40px;
          span {
            color: #00b3d6;
          }
        }
        ul {
          margin-bottom: 10px;
          li {
            div {
              float: left;
              line-height: 27px;
              margin-right: 14px;
              color: #fff;
            }
            p {
              width: 7.45rem /* 740/100 */;
              float: left;
            }
          }
        }
        .three_box {
          margin-bottom: 45px;
        }
      }
    }
  }
  .web {
    header {
      height: 187px;
      padding-top: 80px;
      p {
        font-size: 11px;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        text-align: center;
      }
      h4 {
        font-size: 22px;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        text-align: center;
      }
    }
    main {
      width: 87%;
      margin: auto;
      p {
        font-size: 11px;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        line-height: 18px;
        margin-bottom: 15px;
        span {
          color: #00b3d6;
        }
      }
      ul {
        li {
          div {
            float: left;
            margin-right: 10px;
            color: #fff;
          }
          p {
            width: 85%;
            float: left;
          }
        }
      }
      .four_box {
        padding-bottom: 20px;
      }
    }
    .foot {
      position: relative;
      background: #000;
    }
  }
}
</style>
