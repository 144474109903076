<template>
  <div class="infolist">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <img src="~img/information/01.png" class="logo_img" />
      <img src="~img/information/04.png" class="top_img" />
      <main>
        <div
          class="headlines"
          @click="toDetails(Headlines.informationId, 1,Headlines)"
          v-if="Headlines.informationId"
        >
          <div class="left">
            <img :src="Headlines.iImg" />
          </div>
          <div class="right">
            <p class="title">新闻头条</p>
            <h2>{{ Headlines.iTitle }}</h2>
            <p class="date">{{ Headlines.iDate }}</p>
            <p class="content txt-cut_mor">
              {{ Headlines.iDesc }}
            </p>
            <div class="lock">
              <p class="">查看详情</p>
              <img src="~img/information/05.png" class="icon_img" />
              <img src="~img/information/06.png" class="icon_img_tow" />
            </div>
          </div>
        </div>
        <div class="fenge"></div>
        <ul class="list">
          <li
            v-for="item in list"
            :key="item.informationId"
            @click="toDetails(item.informationId,'',item)"
          >
            <div
              class="img_cont"
              :style="{ background: 'url(' + item.iImg + ')' }"
            >
              <div class="mouk"></div>
              <!-- <img :src="item.iImg" /> -->
            </div>
            <p class="date">{{ item.iDate }}</p>
            <div class="fengexian"></div>
            <h4 class="one-txt-cut">
              {{ item.iTitle }}
            </h4>
            <p class="title txt-cut">
              {{ item.iDesc }}
            </p>
          </li>
        </ul>
        <div class="fenye">
          <el-pagination
            :page-size="pageSize"
            :pager-count="11"
            layout="prev, pager, next,jumper"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <img src="~img/information/04.png" class="web_top_img" />
      <main>
        <div
          class="web_headlines"
          @click="toDetails(Headlines.informationId, 1,Headlines)"
          v-if="Headlines.informationId"
        >
          <img :src="Headlines.iImg" class="tit_img" />
          <div class="tit_text">
            <div class="mack"></div>
            <div class="text_content">
              <p class="title">新闻头条</p>
              <h2>{{ Headlines.iTitle }}</h2>
              <p class="date">{{ Headlines.iDate }}</p>
              <p class="content txt-cut_mor">
                {{ Headlines.iDesc }}
              </p>
              <p class="detail">查看详情 <i class="el-icon-right"></i></p>
            </div>
          </div>
        </div>
        <div class="web_fenge"></div>
        <ul>
          <li
            v-for="item in list"
            :key="item.informationId"
            @click="toDetails(item.informationId,'',item)"
          >
            <img :src="item.iImg" alt="" />
            <p class="date">{{ item.iDate }}</p>
            <div class="li_fenge"></div>
            <p class="title one-txt-cut">{{ item.iTitle }}</p>
            <p class="conten txt-cut_mor">{{ item.iDesc }}</p>
          </li>
        </ul>
        <div class="click_loading">
          <p @click="Turnthepage">点击加载</p>
        </div>
        <up />
      </main>
    </div>
  </div>
</template>



<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up
  },
  data() {
    return {
      w: "",
      equipment: "",
      only: true,
      showFooter: true,
      pageNum: 1,
      pageSize: 6,
      Headlines: "", //头条
      list: [], // 资讯列表
      total: 0,
      istitle: "新闻中心",
      pStatus:''
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    this.pStatus = this.getQueryValue('pStatus')
    console.log(this.pStatus)
    this.getInformation();
    document.title = "企业资讯 - 安谋科技";
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {
    handleCurrentChange(val) {
      console.log(val);
      this.pageNum = val;
      // console.log(document.documentElement.scrollTop);
      if (this.Headlines) {
        document.documentElement.scrollTop = 500;
      } else {
        document.documentElement.scrollTop = 0;
      }
      this.getInformation();
    },
    // 移动端加载
    Turnthepage() {
      this.pageNum++;
      this.getInformation();
    },
    // /v1/api/get_information
    async getInformation() {
      const res = await this.axios.get("/v1/api/get_information", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          pStatus:this.pStatus
        }
      });
      console.log(res);
      if (res.code == "00") {
        this.Headlines = res.data.top;
        if (this.equipment == "pc") {
          this.list = res.data.information.rows;
        } else if (this.equipment == "web") {
          if (res.data.information.rows.length <= 0) {
            this.$toast("暂无数据");
          } else {
            res.data.information.rows.forEach(item => {
              this.list.push(item);
            });
          }
        }
        this.total = res.data.information.total;
      }
    },
    toDetails(id, istop, item) {
      console.log(item);
      if (istop) {
        istop = 1;
      } else {
        istop = "";
      }
      this.$router.push({
        path: `/infodetails?id=${id}&istop=${istop}&istitle=${
          this.istitle
        }&name=${item.iTitle}`
      });
    }
  }
};
</script>


<style lang="less" scoped>
.infolist {
  .pc {
    position: relative;
    background: #000;
    .logo_img {
      width: 0.38rem /* 38/100 */;
      position: absolute;
      top: 327px;
      right: 0.75rem /* 75/100 */;
      z-index: 99;
    }
    .top_img {
      position: absolute;
      top: 0;
    }
    main {
      width: 16.47rem /* 1647/100 */;
      margin: 0 auto;
      padding-top: 158px;
      position: relative;
      .headlines {
        height: 5.53rem /* 553/100 */;
        cursor: pointer;
        .left {
          width: 8.23rem /* 823/100 */;
          height: 100%;
          float: left;
          overflow: hidden;
          img {
            transition: 1s all;
          }
        }
        .right {
          width: 8.24rem /* 824/100 */;
          height: 100%;
          float: left;
          overflow: hidden;
          padding-left: 0.65rem /* 65/100 */;
          padding-top: 0.95rem /* 95/100 */;
          padding-right: 0.54rem /* 54/100 */;
          position: relative;
          .title {
            font-size: 0.14rem /* 14/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.31rem /* 31/100 */;
          }
          h2 {
            font-size: 0.32rem /* 32/100 */;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            color: #ffffff;
            line-height: 0.4rem /* 31/100 */;
            transition: 0.5s all;
          }
          .date {
            font-size: 0.14rem /* 14/100 */;
            font-family: Arial;
            font-weight: 400;
            color: #ffffff;
            margin-top: 0.2rem /* 20/100 */;
          }
          .content {
            font-size: 0.18rem /* 18/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.31rem /* 31/100 */;
            margin-top: 0.3rem /* 30/100 */;
          }
          .lock {
            position: absolute;
            bottom: 0.93rem /* 93/100 */;
            right: 0.84rem /* 84/100 */;
            width: 86px;
            cursor: pointer;
            p {
              font-size: 14px;
              font-family: Microsoft Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 31px;
              float: left;
              transition: 0.5s all;
            }
            .icon_img {
              width: 20px;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0, -50%);
              z-index: 2;
              transition: 0.5s all;
            }
            .icon_img_tow {
              width: 20px;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0, -50%);
              z-index: 1;
              transition: 0.5s all;
            }
          }
        }
      }
      .headlines:hover {
        .left {
          img {
            transform: scale(1.2);
          }
        }
        .right {
          h2 {
            color: #00b3d6;
          }
          .lock {
            p {
              color: #00b3d6;
            }
            .icon_img_tow {
              z-index: 3;
            }
          }
        }
      }
      .fenge {
        width: 258px;
        height: 2px;
        background: #cecece;
        margin-top: 0.94rem /* 94/100 */;
        margin-bottom: 0.98rem /* 98/100 */;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 5.05rem /* 505/100 */;
          height: 4.85rem /* 485/100 */;
          margin-bottom: 1.3rem /* 130/100 */;
          cursor: pointer;
          .img_cont {
            width: 100%;
            height: 3.52rem /* 352/100 */;
            overflow: hidden;
            position: relative;
            background-size: cover !important;
            .mouk {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: #000;
              opacity: 0.7;
              transition: 0.5s all;
            }
          }
          .date {
            font-size: 14px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #9d9d9d;
            margin-top: 0.1rem /* 24/100 */;
          }
          .fengexian {
            width: 100%;
            height: 1px;
            background: #9d9d9d;
            margin-top: 0.06rem /* 12/100 */;
            margin-bottom: 0.06rem /* 12/100 */;
          }
          h4 {
            font-size: 0.16rem /* 16/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: bold;
            color: #e7e7e7;
            line-height: 0.26rem /* 26/100 */;
          }
          .title {
            font-size: 0.14rem /* 14/100 */;
            font-family: Alibaba PuHuiTi;
            font-weight: 300;
            color: #e7e7e7;
            margin-top: 0.1rem /* 10/100 */;
          }
        }
        li:hover {
          .img_cont {
            .mouk {
              opacity: 0;
            }
          }
          .date {
            color: #00b3d6;
          }
          .fengexian {
            background: #00b3d6;
          }
          h4 {
            color: #00b3d6;
          }
        }
      }
      .fenye {
        position: relative;
        height: 160px;
        .el-pagination {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          color: #ffffff;
          background: #000;
          /deep/.el-pagination__editor {
            padding: 0 5px;
            input {
              color: #000;
              font-weight: 600;
            }
          }
          /deep/.el-pagination__jump {
            color: #fff;
          }
          /deep/.btn-next {
            background-color: #000;
            i {
              color: #fff;
            }
          }
          /deep/.btn-prev {
            background-color: #000;
            i {
              color: #fff;
            }
          }
          /deep/.el-pager {
            li {
              background: #424242;
              margin-left: 5px;
            }
            li.active {
              color: #e7e7e7;
              background: #848484;
              min-width: 30px;
            }
          }
        }
      }
    }
  }
  .web {
    background-color: #0f0f0f;
    position: relative;
    .web_top_img {
      position: absolute;
      top: 0;
    }
    .web_logo_img {
      width: 18px;
      position: absolute;
      right: 10px;
      top: 100px;
    }
    main {
      width: 87%;
      margin: 0 auto;
      // background: hotpink;
      position: relative;
      padding-top: 80px;
      .web_headlines {
        .tit_text {
          padding: 10px;
          height: 250px;
          position: relative;
          .mack {
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.3;
            position: absolute;
            left: 0;
            top: 0;
          }
          .text_content {
            position: relative;
            height: 100%;
            .detail {
              font-size: 10px;
              color: #fff;
              position: absolute;
              right: 10px;
              bottom: 5px;
            }
            .title {
              font-size: 14px;
              font-family: Microsoft Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 31px;
            }
            h2 {
              font-size: 18px;
              font-family: Alibaba PuHuiTi;
              font-weight: 500;
              line-height: 25px;
              color: #00b3d6;
            }
            .date {
              font-size: 10px;
              font-family: Arial;
              font-weight: 400;
              color: #ffffff;
              line-height: 31px;
            }
            .content {
              font-size: 12px;
              font-family: Microsoft Microsoft YaHei;
              font-weight: 400;
              color: #e7e7e7;
            }
          }
        }
      }
      .web_fenge {
        width: 100px;
        height: 1px;
        background: #fff;
        margin: auto;
        margin-top: 20px;
      }
      ul {
        margin-top: 45px;
        li {
          margin-bottom: 25px;
          .date {
            font-size: 9px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #0db2d6;
            line-height: 9px;
            margin-top: 15px;
          }
          .li_fenge {
            width: 100%;
            height: 1px;
            background: #0db2d6;
            margin-top: 10px;
          }
          .title {
            font-size: 13px;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            color: #0db2d6;
            line-height: 13px;
            margin-top: 10px;
          }
          .conten {
            font-size: 11px;
            font-family: Alibaba PuHuiTi;
            font-weight: 300;
            color: #e7e7e7;
            line-height: 15px;
            margin-top: 9px;
          }
        }
      }
      .click_loading {
        height: 70px;
        text-align: center;
        p {
          line-height: 70px;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #9d9d9d;
        }
      }
    }
  }
}
</style>

