<template>
  <div class="download">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" :mask="mask" />
      <img src="~img/Thecore/06.png" class="top_img" />
      <main>
        <ul class="naviga">
          <li class="active" v-for="item in downloadList" :key="item.dataId" @click="TheSelected(item)">
            <span>{{ item.dataName }}</span>
            <div class="active" v-if="item.dataId == activeId"></div>
          </li>
        </ul>
        <el-table :data="tableData" border style="width: 12.35rem" @cell-click="download">
          <el-table-column prop="originalName" header-align="center" label="文档名称" min-width="40%"></el-table-column>
          <el-table-column prop="rDesc" header-align="center" label="文件描述" min-width="40%"></el-table-column>
          <el-table-column prop="rType" header-align="center" label="文件类型" min-width="10%"></el-table-column>
          <el-table-column prop="rSize" header-align="center" label="文件大小" min-width="10%"></el-table-column>
        </el-table>
        <!-- 底部资料导航 -->
        <ul class="foter_nav">
          <li v-for="val in secondary" :key="val.dataId">
            <div @click="dataList(val)">{{ val.dataName }} <span>—</span></div>
            <p v-for="Level3 in val.children" :key="Level3.dataId" @click="dataList(Level3)"
              :class="{ active_color: Level3.dataId == levId }">
              {{ Level3.dataName }}
            </p>
          </li>
        </ul>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <webNav v-if="equipment == 'web'" :mask="mask" />
      <div class="top_box"></div>
      <img src="~img/Thecore/12.png" class="top_img" />
      <main>
        <div class="btn_box">
          <div class="btn" v-for="weblev2 in secondary" :key="weblev2.dataId">
            <button @click="weblev(weblev2)">
              {{ weblev2.dataName }}
              <van-icon name="arrow-down" />
            </button>
            <ul>
              <li v-for="item in weblev2.children" :key="item.dataId" :class="{ showlev: weblev2.dataId == weblev3 }"
                class="one-txt-cut" @click="choicelev(item)">
                {{ item.dataName }}
              </li>
            </ul>
          </div>
        </div>
        <div class="web_nav">
          <ul class="clearfix">
            <li v-for="web in downloadList" :key="web.dataId" @click="choice(web)">
              {{ web.dataName }}
              <div class="xian" v-if="web.dataId == activeId"></div>
            </li>
          </ul>
        </div>
        <div class="web_list">
          <div class="header">下载列表</div>
          <ul>
            <li v-for="item in tableData" :key="item.resourceId">
              <h3>
                {{ item.originalName }}
              </h3>
              <h4>
                {{ item.rDesc }}
              </h4>
              <p>{{ item.rSize }} {{ item.rType }}</p>
              <button @click="download(item)">下载</button>
            </li>
          </ul>
        </div>
      </main>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>


<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import $ from "jquery";
import up from "@/components/Backtotop.vue";
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: undefined,
      equipment: "",
      only: true,
      mask: "NO",
      showFooter: true,
      tableData: [],
      downloadList: [], // 下载列表
      activeId: "", // 选中的id
      secondary: [], // 二级列表
      levId: "",
      weblev3: "",
      path: [],
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    this.infoId = this.getQueryValue("infoId");
    // this.path = decodeURIComponent(this.getQueryValue("path"));
    let params = this.$route.params;
    // console.log(params);
    Object.keys(params).forEach((key) => {
      console.log(params[key]);
      if (params[key] != undefined) {
        this.path.push(params[key]);
      }
    });
    console.log(this.path);
    this.getDetail();
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        this.h = document.documentElement.clientHeight; // 视口的高度
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {
    async getDetail() {
      const res = await this.axios(
        `/v1/api/corepower_detail?infoId=${this.infoId}`
      );
      if (res.code == "00") {
        this.downloadList = res.detailResurce;
        if (this.downloadList) {
          this.downloadList.forEach((item) => {
            item.children.forEach((lev2) => {
              lev2.resourceList.forEach((res) => {
                res.rSize = res.rSize + "MB";
              });
              lev2.children.forEach((lev3) => {
                lev3.resourceList.forEach((res3) => {
                  res3.rSize = res3.rSize + "MB";
                });
              });
            });
          });
          if (this.path.length > 0) {
            let list = this.downloadList; // 拿到所有数据
            let res = []; // 用于存储递归结果（扁平数据
            // 递归函数
            const fn = (source) => {
              source.forEach((el) => {
                res.push(el);
                el.children && el.children.length > 0 ? fn(el.children) : ""; // 子级递归
              });
            };
            fn(list); // 树形结构扁平化
            // let level = this.path.join("/");
            // console.log(level);
            let leve1 = "/" + this.path[0];
            // let levelList = this.path.split("/"); // 把路径path后的参数按照 / 来分割
            // levelList.shift(); // 删除第一个空字符串
            // levelList[0] = "/" + levelList[0]; // 拿到数组中的第一个 一级数据
            this.downloadList.forEach((item) => {
              // 遍历数据找到对应的一级数据
              if (item.path == leve1) {
                this.activeId = item.dataId; // 页面进来默认选中第一级
                this.secondary = item.children; // 第一级下面的列表
              }
            });
            let pathName = this.path.join("/");
            pathName = "/" + pathName;
            res.forEach((val) => {
              if (val.path == pathName) {
                this.levId = val.dataId;
                this.tableData = val.resourceList;
              }
            });
          } else {
            this.activeId = this.downloadList[0].dataId; // 页面进来默认选中第一级
            this.secondary = this.downloadList[0].children; // 第一级下面的列表
            this.levId = this.secondary[0].children[0].dataId;
            // 2021 9 29 需求默认展示 errata-en的数据
            this.tableData = this.secondary[0].children[0].resourceList;
            // console.log(this.secondary[0].resourceList);
          }
        }
      } else {
        this.$message.error("数据请求失败");
      }
    },
    async download(row, column, cell, event) {
      window.open(row.url);
      // console.log(row.url);
      // window.open(`http://121.5.58.131:8080/common/download/${row.resourceId}`);
      // http://121.5.58.131:8080
      // https://www.armchina.com/webarm/arm
      // let request = new XMLHttpRequest();
      // request.responseType = "blob";
      // let fileUrl = row.url; // 文件路径
      // request.open("GET", fileUrl);
      // request.onload = function () {
      //   let url = window.URL.createObjectURL(this.response);
      //   let a = document.createElement("a");
      //   document.body.appendChild(a);
      //   a.href = url;
      //   a.download = row.dataName + row.rType;
      //   a.click();
      // };
      // request.send();
    },
    // 点击一级菜单pc
    TheSelected(item) {
      this.$router.push({
        path: `/download${item.path}?infoId=${this.infoId}`,
      });
      // console.log(decodeURIComponent(path));
      this.activeId = item.dataId; // 一级标题id
      this.secondary = this.downloadList.filter(
        (val) => val.dataId == this.activeId
      )[0].children; // 一级标题下二级菜单
      // console.log(this.secondary);
      if (this.secondary[0].children.length > 0) {
        this.tableData = this.secondary[0].children[0].resourceList; // 列表数据
        this.levId = this.secondary[0].children[0].dataId;
      } else {
        this.tableData = this.secondary[0].resourceList;
        this.levId = this.secondary[0].dataId;
      }
    },
    // 点击二级 三级 pc
    dataList(val) {
      this.$router.push({ path: `/download${val.path}?infoId=${this.infoId}` });
      // let path = this.getQueryValue("path");
      // console.log(decodeURIComponent(path));
      this.levId = val.dataId;
      if (val.resourceList.length > 0) {
        this.tableData = val.resourceList;
      } else {
        this.tableData = [];
      }
    },
    // web 点击第一级
    choice(item) {
      this.$router.push({
        path: `/download${item.path}?infoId=${this.infoId}`,
      });
      this.activeId = item.dataId;
      this.secondary = this.downloadList.filter(
        (val) => val.dataId == this.activeId
      )[0].children;
      // this.tableData = [];
      // console.log(this.secondary);
      if (this.secondary[0].children.length > 0) {
        this.tableData = this.secondary[0].children[0].resourceList; // 列表数据
        this.levId = this.secondary[0].children[0].dataId;
      } else {
        this.tableData = this.secondary[0].resourceList;
        this.levId = this.secondary[0].dataId;
      }
    },
    // 移动端点击二级
    weblev(val) {
      this.$router.push({ path: `/download${val.path}?infoId=${this.infoId}` });
      this.weblev3 = val.dataId;
      if (val.resourceList.length > 0) {
        this.tableData = val.resourceList;
      } else {
        this.tableData = [];
      }
    },
    // 移动端点击第三级
    choicelev(val) {
      this.$router.push({ path: `/download${val.path}?infoId=${this.infoId}` });
      if (val.resourceList.length > 0) {
        this.tableData = val.resourceList;
        this.activeId = "";
      } else {
        this.tableData = [];
      }
      // console.log(this.tableData);
    },
  },
};
</script>

<style lang="less" scoped>
.download {
  background: #ededed;
  // height: 2000px;
  .pc {
    position: relative;
    .top_img {
      width: 10.75rem /* 1075/100 */;
      height: 7.63rem /* 763/100 */;
      position: absolute;
      top: 0;
      right: 0;
    }
    main {
      position: relative;
      padding-top: 3.46rem /* 346/100 */;
      padding-left: 1.34rem /* 134/100 */;
      min-height: 300px;
      .naviga {
        display: flex;
        margin-bottom: 0.49rem /* 49/100 */;
        li {
          font-size: 0.4rem /* 40/100 */;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          color: #000000;
          margin-right: 0.76rem /* 76/100 */;
          cursor: pointer;
          height: 0.7rem /* 60/100 */;
          position: relative;
          .active {
            width: 0.38rem /* 38/100 */;
            height: 3px;
            background: #01b2db;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%);
          }
        }
      }
      .el-table {
        border: 1px solid #c2c7d4;
        color: #505050;
        margin-bottom: 0.88rem /* 88/100 */;
        /deep/.has-gutter {
          tr {
            background-color: #c2c7d4;
            font-weight: 400;
            th {
              background-color: #e7e8e8;
              border-bottom: 1px solid #c2c7d4;
              border-right: 1px solid #c2c7d4;
            }
          }
        }
        /deep/td {
          border-right: 1px solid #c2c7d4;
          border-bottom: 1px solid #c2c7d4;
          cursor: pointer;
        }
        /deep/td:hover {
          color: #01b2db;
          .cell {
            text-decoration: underline;
          }
        }
      }
      .foter_nav {
        position: absolute;
        bottom: 0 /* 88/100 */;
        right: 0.82rem /* 82/100 */;
        li {
          text-align: right;
          font-size: 0.18rem /* 18/100 */;
          font-family: Alibaba PuHuiTi;
          font-weight: 600;
          color: #000000;
          line-height: 40px;
          cursor: pointer;
          p {
            font-size: 0.18rem /* 18/100 */;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #505050;
            line-height: 40px;
          }
          .active_color {
            color: #01b2db;
          }
        }
      }
    }
  }
  .web {
    background: #ededed;
    position: relative;
    .top_box {
      height: 50px;
    }
    main {
      padding-left: 27px;
      padding-right: 24px;
      .btn_box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        button {
          width: 100%;
          height: 35px;
          background: #0eb1db;
          color: #fff;
          position: relative;
          font-size: 11px;
          // margin-right: 15px;
          padding-right: 10px;
          overflow: hidden;
          .van-icon {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
        .btn {
          width: 45%;
        }
        ul {
          li {
            width: 100%;
            height: 0px;
            background: #fff;
            transition: 0.5s all;
            opacity: 0;
            text-align: center;
            line-height: 35px;
            padding-left: 10px;
            padding-right: 10px;
            border-bottom: 1px solid #e7e8e8;
          }
          .showlev {
            height: 35px;
            opacity: 1;
          }
        }
      }
      .web_nav {
        margin-top: 45px;
        ul {
          li {
            float: left;
            width: 50%;
            text-align: center;
            height: 40px;
            font-size: 20px;
            font-family: AlibabaPuHuiTi;
            font-weight: 500;
            color: #000000;
            line-height: 30px;
            position: relative;
            .xian {
              width: 19px;
              height: 2px;
              background: #0eb1db;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%);
            }
          }
        }
      }
      .web_list {
        width: 100%;
        border: 1px solid #c2c7d4;
        margin-top: 26px;
        padding-bottom: 28px;
        .header {
          height: 35px;
          font-size: 15px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #505050;
          text-align: center;
          background: #e7e8e8;
          line-height: 35px;
        }
        ul {
          li {
            overflow: hidden;
            margin-left: 29px;
            padding-bottom: 20px;
            margin-right: 30px;
            margin-top: 25px;
            position: relative;
            border-bottom: 1px solid #505050;
            h3 {
              font-size: 11px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              text-decoration: underline;
              color: #505050;
              line-height: 18px;
              word-wrap: break-word;
            }
            h4 {
              font-size: 9px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #505050;
              margin-top: 14px;
            }
            p {
              font-size: 11px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              text-decoration: underline;
              color: #505050;
              line-height: 18px;
              margin-top: 15px;
            }
            button {
              width: 50px;
              height: 20px;
              border: 1px solid #505050;
              position: absolute;
              right: 0;
              bottom: 15px;
              background: transparent;
            }
          }
        }
      }
    }
    .foot {
      background: #000;
    }
  }
}
</style>


