<template>
  <div class="activitylist">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <img src="~img/information/10.png" class="top_img" />
      <img src="~img/information/13.png" class="logo_img" />
      <header @click="newsDeta(recommended)" v-if="recommended.newsId">
        <img :src="recommended.nImg" />
        <div class="content">
          <h4>{{ recommended.nLocale }}</h4>
          <h2>{{ recommended.nTitle }}</h2>
          <div class="xian"></div>
          <p>{{ recommended.nTime }}</p>
          <button>查看详情</button>
        </div>
      </header>
      <main>
        <div class="tag" :class="{ topmou: !recommended.newsId }"></div>
        <ul>
          <!-- n_position      标题位置（1：左上    2：左下   3：右上   4：右下    5:居中）', -->
          <li v-for="item in list" :key="item.newsId" :style="{ background: 'url(' + item.nImg + ')' }" @click="newsDeta(item)" :class="{li_top:item.nTitle.length > 16}">
            <!-- <img :src="item.nImg" > -->
            <div class="middle" v-if="item.nPosition == 5">
              <h4 v-if="item.titleHide == 1">{{ item.nTitle }}</h4>
              <h5>{{ item.nDesc }}</h5>
              <p>{{ item.nLocale }} {{ item.nTime }}</p>
              <button>查看详情</button>
            </div>
            <div class="right_btn" v-if="item.nPosition == 4">
              <h4 v-if="item.titleHide == 1">{{ item.nTitle }}</h4>
              <h5>{{ item.nDesc }}</h5>
              <p>{{ item.nLocale }} {{ item.nTime }}</p>
              <button>查看详情</button>
            </div>
            <div class="right_top" v-if="item.nPosition == 3">
              <h4 v-if="item.titleHide == 1">{{ item.nTitle }}</h4>
              <h5>{{ item.nDesc }}</h5>
              <p>{{ item.nLocale }} {{ item.nTime }}</p>
              <button>查看详情</button>
            </div>
          </li>
        </ul>
        <div class="fenye">
          <el-pagination :page-size="4" :pager-count="11" layout="prev, pager, next,jumper" :total="total" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <img src="~img/information/15.png" class="top_img" />
      <header>
        <div class="recom" @click="newsDeta(recommended)" v-if="recommended.newsId">
          <img :src="recommended.nImg" />
          <div class="content">
            <h4>{{ recommended.nLocale }}</h4>
            <h3>{{ recommended.nTitle }}</h3>
            <div class="web_xian"></div>
            <p>{{ recommended.nTime }}</p>
            <button>查看详情</button>
          </div>
        </div>
      </header>
      <main>
        <div class="web_biaoji"></div>
        <ul>
          <li v-for="item in list" :key="item.newsId" :style="{ background: 'url(' + item.nImg + ')' }" @click="newsDeta(item)">
            <!-- <img :src="item.nImg"> -->
            <div class="web_middle" v-if="item.nPosition == 5">
              <h4 v-if="item.titleHide == 1" class="one-txt-cut">
                {{ item.nTitle }}
              </h4>
              <h5>{{ item.nDesc }}</h5>
              <p>{{ item.nLocale }} {{ item.nTime }}</p>
              <button>查看详情</button>
            </div>
            <div class="web_right_btn" v-if="item.nPosition == 4">
              <h4 v-if="item.titleHide == 1" class="one-txt-cut">
                {{ item.nTitle }}
              </h4>
              <h5>{{ item.nDesc }}</h5>
              <p>{{ item.nLocale }} {{ item.nTime }}</p>
              <button>查看详情</button>
            </div>
            <div class="web_right_top" v-if="item.nPosition == 3">
              <h4 v-if="item.titleHide == 1" class="one-txt-cut">
                {{ item.nTitle }}
              </h4>
              <h5>{{ item.nDesc }}</h5>
              <p>{{ item.nLocale }} {{ item.nTime }}</p>
              <button>查看详情</button>
            </div>
          </li>
        </ul>
        <div class="click_loading">
          <p @click="Turnthepage">点击加载</p>
        </div>
        <up />
      </main>
    </div>
  </div>
</template>

<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: undefined,
      equipment: "",
      only: true,
      mask: "NO",
      showFooter: true,
      total: 10,
      pageNum: 1,
      pageSize: 4,
      recommended: {}, // 推荐
      list: [],
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    if (this.equipment == "pc") {
      this.getNews();
    }
    if (this.equipment == "web") {
      this.webNews();
    }
    document.title = "公司活动 - 安谋科技";
  },
  mounted() {
    document.title = "公司活动 - 安谋科技";
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {
    newsDeta(item) {
      console.log(item);
      if (item.target) {
        window.open(item.target, "_blank");
      } else {
        this.$router.push({ path: `/infodetails?newsid=${item.newsId}` });
      }
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pageNum = val;
      if (this.recommended) {
        document.documentElement.scrollTop = 500;
      } else {
        document.documentElement.scrollTop = 0;
      }
      this.getNews();
    },
    Turnthepage() {
      this.pageNum++;
      this.webNews();
    },
    async webNews() {
      const res = await this.axios.get("/v1/api/get_news", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      });
      if (res.code == "00") {
        this.recommended = res.data.top;
        if (res.data.news.rows.length <= 0) {
          this.$toast("暂无数据");
        } else {
          res.data.news.rows.forEach((item) => {
            console.log(item);
            this.list.push(item);
          });
          console.log(this.list);
        }
      }
    },
    async getNews() {
      const res = await this.axios.get("/v1/api/get_news", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      });
      if (res.code == "00") {
        this.recommended = res.data.top;
        this.list = res.data.news.rows;
        this.total = res.data.news.total;
        // console.log(this.recommended);
        // console.log(this.list);
        // this.list.forEach((item) => {
        //   item.nTitle = "2022中国集成电路设计创新大会暨IC应用博览会";
        // });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.activitylist {
  background: #0f0f0f;
  .pc {
    position: relative;
    border-top: 1px solid #0f0f0f;
    .top_img {
      position: absolute;
      top: 0;
    }
    .logo_img {
      width: 0.38rem /* 38/100 */;
      position: absolute;
      top: 3.27rem /* 327/100 */;
      right: 0.75rem /* 75/100 */;
    }
    header {
      position: relative;
      width: 16.41rem /* 1641/100 */;
      margin: 0 auto;
      margin-top: 2.68rem /* 268/100 */;
      height: 6.25rem /* 625/100 */;
      padding-left: 0.68rem /* 68/100 */;
      padding-top: 0.84rem /* 84/100 */;
      cursor: pointer;
      overflow: hidden;
      img {
        position: absolute;
        left: 0;
        top: 0;
        transition: 1s all;
      }

      .content {
        position: relative;
        h4 {
          font-size: 0.4rem /* 40/100 */;
          font-family: Microsoft YaHei;
          font-weight: 500;
          color: #ffffff;
        }
        h2 {
          width: 7.65rem /* 665/100 */;
          font-size: 1.13rem /* 113/100 */;
          font-family: Microsoft YaHei;
          font-weight: 500;
          color: #ffffff;
          line-height: 1.3rem;
        }
        .xian {
          width: 43px;
          height: 4px;
          background: #ffffff;
          margin-top: 0.5rem /* 85/100 */;
        }
        p {
          font-size: 0.4rem /* 40/100 */;
          font-family: Microsoft YaHei;
          font-weight: 500;
          color: #ffffff;
        }
        button {
          width: 124px;
          height: 35px;
          border: 1px solid #ffffff;
          border-radius: 18px;
          background: transparent;
          color: #fff;
          font-size: 0.16rem /* 16/100 */;
          margin-top: 0.25rem /* 50/100 */;
          cursor: pointer;
        }
      }
    }
    header:hover {
      img {
        transform: scale(1.1);
      }
    }
    main {
      width: 16.41rem /* 1641/100 */;
      margin: auto;
      margin-top: 0.92rem /* 92/100 */;
      .tag {
        width: 258px;
        height: 2px;
        background: #cecece;
        margin-bottom: 0.94rem /* 94/100 */;
      }
      .topmou {
        margin-top: 2.5rem /* 50/100 */;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 7.9rem /* 790/100 */;
          height: 4.24rem /* 424/100 */;
          background: #000;
          margin-bottom: 0.6rem /* 60/100 */;
          padding-top: 1.3rem /* 130/100 */;
          position: relative;
          // background: url("~img/information/12.png") no-repeat;
          background-size: cover !important;
          overflow: hidden;
          cursor: pointer;
          transition: 0.1s all;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
          .middle {
            text-align: center;
            position: relative;
            padding: 0 0.2rem;
            h4 {
              font-size: 0.4rem /* 66/100 */;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.66rem /* 66/100 */;
            }
            h5 {
              font-size: 0.23rem /* 23/100 */;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.37rem /* 37/100 */;
              margin-top: 0.24rem /* 24/100 */;
            }
            p {
              font-size: 0.2rem /* 26/100 */;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.26rem; /* 26/100 */
              margin-top: 0.39rem /* 39/100 */;
            }
            button {
              width: 124px;
              height: 35px;
              border: 1px solid #ffffff;
              border-radius: 18px;
              background: transparent;
              margin-top: 0.15rem /* 15/100 */;
              font-size: 0.16rem /* 16/100 */;
              color: #fff;
              cursor: pointer;
            }
          }

          .right_btn {
            position: absolute;
            right: 0.26rem /* 26/100 */;
            bottom: 0.27rem /* 27/100 */;
            text-align: right;
            h4 {
              font-size: 0.5rem /* 66/100 */;
              font-family: Gotham Medium;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.66rem /* 66/100 */;
            }
            h5 {
              font-size: 0.28rem /* 28/100 */;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.28rem /* 28/100 */;
              margin-top: 15px;
            }
            p {
              font-size: 0.2rem /* 26/100 */;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.26rem /* 26/100 */;
              margin-top: 0.13rem /* 13/100 */;
            }
            button {
              width: 1.24rem /* 124/100 */;
              height: 35px;
              border: 1px solid #ffffff;
              border-radius: 18px;
              background: transparent;
              color: #fff;
              margin-top: 0.34rem /* 34/100 */;
              cursor: pointer;
            }
          }
          .right_top {
            position: absolute;
            right: 0.26rem /* 26/100 */;
            top: 0.27rem /* 27/100 */;
            text-align: right;
            h4 {
              font-size: 0.5rem /* 66/100 */;
              font-family: Gotham Medium;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.66rem /* 66/100 */;
            }
            h5 {
              font-size: 0.28rem /* 28/100 */;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.28rem /* 28/100 */;
              margin-top: 15px;
            }
            p {
              font-size: 0.2rem /* 26/100 */;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 0.26rem /* 26/100 */;
              margin-top: 0.13rem /* 13/100 */;
            }
            button {
              width: 1.24rem /* 124/100 */;
              height: 35px;
              border: 1px solid #ffffff;
              border-radius: 18px;
              background: transparent;
              color: #fff;
              margin-top: 0.34rem /* 34/100 */;
              cursor: pointer;
            }
          }
        }
        .li_top {
          padding-top: 0.8rem /* 130/100 */;
        }
      }
      .fenye {
        position: relative;
        height: 160px;
        margin-top: 0.5rem /* 50/100 */;
        .el-pagination {
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          color: #ffffff;
          background: #0f0f0f;
          /deep/.el-pagination__editor {
            padding: 0 5px;
            input {
              color: #000;
              font-weight: 600;
            }
          }
          /deep/.el-pagination__jump {
            color: #fff;
          }
          /deep/.btn-next {
            background-color: #0f0f0f;
            i {
              color: #fff;
            }
          }
          /deep/.btn-prev {
            background-color: #0f0f0f;
            i {
              color: #fff;
            }
          }
          /deep/.el-pager {
            li {
              background: #0f0f0f;
              margin-left: 5px;
            }
            li.active {
              color: #e7e7e7;
              background: #848484;
              min-width: 30px;
            }
          }
        }
      }
    }
  }
  .web {
    background: #000;
    position: relative;
    min-height: 100vh;
    .top_img {
      position: absolute;
      top: 0;
    }
    header {
      padding-top: 108px;
      padding-left: 25px;
      padding-right: 24px;
      .recom {
        position: relative;
        height: 186px;
        padding-left: 14px;
        padding-top: 17px;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: auto;
        }
        .content {
          position: relative;
          h4 {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 500;
            color: #ffffff;
          }
          h3 {
            font-size: 34px;
            font-family: Microsoft YaHei;
            font-weight: 500;
            color: #ffffff;
            line-height: 37px;
            margin-top: 6px;
            width: 300px;
          }
          .web_xian {
            width: 13px;
            height: 1px;
            background: #ffffff;
            margin-top: 15px;
          }
          p {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 500;
            color: #ffffff;
          }
          button {
            width: 68px;
            height: 19px;
            border: 1px solid #ffffff;
            border-radius: 10px;
            font-size: 9px;
            background: transparent;
            color: #ffffff;
            margin-top: 8px;
          }
        }
      }
    }
    main {
      margin-top: 38px;
      padding-left: 25px;
      padding-right: 25px;
      position: relative;
      .web_biaoji {
        width: 129px;
        height: 1px;
        background: #cecece;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
      }
      ul {
        padding-top: 40px;
        li {
          height: 174px;
          margin-bottom: 18px;
          position: relative;
          overflow: hidden;
          background-size: cover !important;
          img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
          }
          .web_middle {
            position: relative;
            text-align: center;
            padding-top: 35px;
            h4 {
              font-size: 23px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 44px;
            }
            h5 {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 15px;
            }
            p {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              margin-top: 10px;
            }
            button {
              width: 67px;
              height: 19px;
              border: 1px solid #ffffff;
              border-radius: 10px;
              background: transparent;
              color: #fff;
              font-size: 9px;
              margin-top: 20px;
            }
          }
          .web_right_btn {
            position: absolute;
            right: 10px;
            bottom: 12px;
            text-align: right;
            h4 {
              font-size: 23px;
              font-family: Gotham Medium;
              font-weight: 400;
              color: #ffffff;
              line-height: 44px;
            }
            h5 {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 19px;
            }
            p {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
            }
            button {
              width: 67px;
              height: 19px;
              border: 1px solid #ffffff;
              border-radius: 10px;
              background: transparent;
              color: #fff;
              font-size: 9px;
              margin-top: 15px;
            }
          }
          .web_right_top {
            position: absolute;
            right: 10px;
            top: 12px;
            text-align: right;
            h4 {
              font-size: 23px;
              font-family: Gotham Medium;
              font-weight: 400;
              color: #ffffff;
              line-height: 44px;
            }
            h5 {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
              line-height: 19px;
            }
            p {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 500;
              color: #ffffff;
            }
            button {
              width: 67px;
              height: 19px;
              border: 1px solid #ffffff;
              border-radius: 10px;
              background: transparent;
              color: #fff;
              font-size: 9px;
              margin-top: 15px;
            }
          }
        }
      }
      .click_loading {
        height: 70px;
        text-align: center;
        p {
          line-height: 70px;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #9d9d9d;
        }
      }
    }
  }
}
</style>
