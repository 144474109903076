<template>
  <div class="mountain">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" :mask="mask" />
      <header>
        <div class="title">
          <h2 v-if="dTitle">{{ dTitle }}</h2>
          <p>{{ dTitleext }}</p>
          <h3 v-if="errortext != ''">{{ errortext }}</h3>
        </div>
      </header>
      <main>
        <div class="content" v-for="(item, index) in list" :key="index">
          <div v-if="item.resource == 2 || item.resource == 0">
            <div class="left_right clearfix" v-if="item.style == 1">
              <p v-html="item.content.text"></p>
              <img :src="item.content.img" />
            </div>
            <div class="right_left clearfix" v-if="item.style == 9">
              <img :src="item.content.img" />
              <p v-html="item.content.text"></p>
            </div>
            <div class="img_box" v-if="item.style == 2">
              <img :src="item.content.img" alt="" />
            </div>
            <div class="img_box_t" v-if="item.style == 8">
              <img :src="item.content.img" alt="" />
            </div>
            <div class="list_box" v-if="item.style == 3">
              <div class="list" v-html="item.content.text"></div>
              <!-- <div class="shanhai" v-if="dTitleext == 'E10/E20'">
                <div class="box_list">
                  <h3>核心安全引擎──TrustEngine-200</h3>
                  <h4>
                    “山海”E10/E20 的核心模块 TrustEngine-200
                    是密码算法引擎，其包含国际通用算法以及中国商用密码算法。
                  </h4>
                  <ul>
                    <li>摘要算法：SHA1/224/256、SM3</li>
                    <li>对称算法：AES128/192/256、SM4</li>
                    <li>非对称算法：RSA1024/2048/4096、ECCP 128/256/521、SM2</li>
                    <li>
                      OTP：设备 ID/Key、Model ID/Key、OTP
                      锁定、生命周期管理，用户可自定义 OTP 区域
                    </li>
                    <li>物理防克隆功能 （PUF）</li>
                    <li>TRNG：按照中国商用密码标准</li>
                  </ul>
                </div>
                <div class="box_list">
                  <h3>TrustZone/可信执行环境（“山海”E20)</h3>
                  <h4>
                    可信执行环境（TEE）能够为需要安全保护的应用和逻辑提供一个安全的运行环境，确保敏感数据和安全业务在隔离、可信的环境中存储、执行和保护。
                  </h4>
                  <ul>
                    <li>
                      基本能力：
                      <p>-基于 TEE 的高安全 TLS</p>
                      <p>-安全存储</p>
                      <p>-远程认证能力</p>
                    </li>
                    <li>支持客户定制化</li>
                    <li>
                      资质认证
                      <p>PSA Level 1，已通过认证</p>
                    </li>
                  </ul>
                </div>
                <div class="box_list">
                  <h3>其他安全能力</h3>
                  <ul>
                    <li>
                      安全启动:
                      <p>-设备基础安全能力</p>
                      <p>-一站式验证</p>
                      <p>-提供在线/离线签名工具</p>
                      <p>支持 image 加密和安全热修复（Hotfix）</p>
                    </li>
                  </ul>
                </div>
                <div class="box_list top_box">
                  <ul>
                    <li>
                      安全调试
                      <p>-设备部署后的安全调试</p>
                      <p>基于 SaaS 的安全调试方案</p>
                    </li>
                    <li>
                      安全烧录
                      <p>-通过 SaaS 机制实现安全引擎的安全预配</p>
                      <p>-基于 SaaS 的安全烧录方案</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="shanhais12" v-if="dTitleext == 'S12'">
                <div class="s12_box">
                  <h3>核心安全引擎──TrustEngine-600</h3>
                  <h4>
                    “山海”S12的核心模块 TrustEngine-600
                    是密码算法引擎，支持国际通用算法以及中国商用密码算法。“山海”S12硬件算法引擎默认支持
                    Arm
                    TrustZone，其算法及安全能力可以根据需要灵活配置。此外，客户既可以使用安谋科技提供的随机数方案也可以自定义随机数方案。
                  </h4>
                  <ul>
                    <li>摘要算法：SHA1/224/256/384/512、SM3。</li>
                    <li>对称算法：AES128/192/256/512、SM4。</li>
                    <li>
                      非对称算法：RSA1024/2048/3072/4096/8192、ECCP
                      <p>128/256/384/512/521、SM2。</p>
                    </li>
                    <li>
                      OTP：设备 ID/Key、Model ID/Key、OTP
                      锁定、生命周期管理。用户可自定义 OTP 区域。
                    </li>
                    <li>物理防克隆功能（PUF）。</li>
                    <li>真随机数生成器（TRNG）：按照中国商用密码标准。</li>
                    <li>
                      支持多达 16 个 Host 同时访问，为多应用场景提供安全支持。
                    </li>
                    <li>生命周期安全管理（CM、DM、DD、DR）。</li>
                    <li>密钥管理。</li>
                    <li>Link List 模式。</li>
                  </ul>
                </div>
                <div class="s12_box">
                  <h3>TrustZone/可信执行环境（TEE）</h3>
                  <h4>
                    可信执行环境能够为需要安全保护的应用和逻辑提供一个安全的运行环境，确保敏感数据和安全业务在隔离、可信的环境中存储、执行和保护。
                  </h4>
                  <ul>
                    <li>
                      基本能力：
                      <p>-基于 TEE 的高安全 TLS。</p>
                      <p>-安全存储，安全连接。</p>
                      <p>-远程认证能力。</p>
                    </li>
                    <li>遵循 GP 标准，且支持客户定制化需求。</li>
                  </ul>
                  <h3 class="rith_h3">其他安全能力</h3>
                  <ul>
                    <li>
                      安全启动：
                      <p>-设备基础安全能力。</p>
                      <p>-站式验证。</p>
                    </li>
                    <li>
                      安全调试：
                      <p>-设备部署后的安全调试</p>
                      <p>-基于 SaaS 的安全调试方案。</p>
                    </li>
                    <li>
                      安全烧录：
                      <p>-通过 SaaS 机制实现安全引擎的安全预配。</p>
                      <p>-基于 SaaS 的安全烧录方案。</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="linglong" v-if="dTitleext == 'i3/i5 ISP'">
                <div class="long_top_box">
                  <h3>技术亮点</h3>
                  <ul>
                    <li>硬件架构灵活可配置，客户可自行选配可选模块进行集成</li>
                    <li>
                      多元的工作模式，可兼容线性、原始/压缩的 HDR
                      数据，单路及多路摄像头输入，支持超高分辨率分屏处理
                    </li>
                    <li>
                      DMA 接口数据输入输出模式可配，可在 ISP
                      多个节点输出不同格式的数据
                    </li>
                    <li>软件 API 接口丰富，图像效果调试流程简易清晰</li>
                    <li>
                      提供丰富的软硬件参考设计，如标定工具、调优工具、MIPI 转 DVP
                      数字电路等
                    </li>
                  </ul>
                </div>
                <div class="long_bnt">
                  <h3>广受好评的宽动态效果</h3>
                  <ul>
                    <li>数字宽动态与融合宽动态结合</li>
                    <li>像素级的局部宽动态自适应提升</li>
                    <li>兼容常见 Sensor 厂商的融合宽动态格式</li>
                  </ul>
                </div>
                <div class="long_bnt">
                  <h3>业内领先的信噪比表现</h3>
                  <ul>
                    <li>增强的 2D/3D 自适应降噪技术</li>
                    <li>多尺度，多级降噪技术</li>
                    <li>多级细节增强提升技术</li>
                  </ul>
                </div>
                <div class="long_bnt">
                  <h3>低延时低系统带宽</h3>
                  <ul>
                    <li>实时在线视频信号处理，低系统延时</li>
                    <li>支持 DVP 输入输出，进一步减少系统带宽</li>
                  </ul>
                </div>
                <div class="long_bnt">
                  <h3>丰富的系统中断和调试接口</h3>
                  <ul>
                    <li>多个 AE/AWB/AF 统计输出</li>
                    <li>友好的系统状态及异常调试中断设计</li>
                  </ul>
                </div>
                <div class="long_bnt">
                  <h3>图像质量调优技术支持</h3>
                  <ul>
                    <li>提供镜头模组标定及图像调优的培训</li>
                    <li>即时可用的 Sensor 调优参数库文件</li>
                    <li>提供灵活的特定模组标定及调优服务</li>
                  </ul>
                </div>
                <div class="long_bnt">
                  <h3>从评估到量产的全方位技术支持</h3>
                  <ul>
                    <li>丰富的 ISP 图像效果评估手段</li>
                    <li>提供 IP 集成及后端的参考流程</li>
                    <li>详细的设计文档和培训</li>
                  </ul>
                </div>
              </div>
              <div class="xingcheng" v-if="dTitleext == 'STAR-MC1'">
                <div class="mc1_box">
                  <h3>技术亮点：</h3>
                  <ul>
                    <li>Armv8-M架构，带来高达4.02Coremark/MHz的极致能效</li>
                    <li>
                      内存子系统引入紧耦合内存和缓存技术，确保实时性和执行效率
                    </li>
                    <li>DSP指令和协处理器，帮助实现算法性能的大幅度提升</li>
                    <li>TrustZone技术，确保物联网设备的信息安全</li>
                  </ul>
                </div>
              </div> -->
            </div>
            <div class="download" v-if="item.style == 7">
              <button @click="download">资源下载</button>
              <p v-html="item.content.text"></p>
            </div>
            <div v-if="item.style == 5" class="video_list">
              <button>查看Demo</button>
              <ul>
                <li v-for="videolist in item.content.videos" :key="videolist.video" @click="ViewVideo(videolist)">
                  {{ videolist.text }}
                </li>
              </ul>
            </div>
            <p class="video_P" v-if="item.style == 5" v-html="item.content.text"></p>
            <div class="foot_title" v-if="item.style == 4">
              <div v-html="item.content.text"></div>
            </div>
            <div class="list_top" v-if="item.style == 6" v-html="item.content.text"></div>
          </div>
        </div>
      </main>
      <!-- 视频 -->
      <div class="modal" v-show="dialogVisible">
        <video controls="false" class="xuan_video" :poster="videoImg" ontrols="controls" :src="videoUrl">
          您的浏览器不支持Video标签。
        </video>
        <i class="el-icon-close" @click="dialogVisible = false"></i>
        <div class="mack"></div>
      </div>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <webNav v-if="equipment == 'web'" :mask="mask" />
      <header>
        <h2>
          {{ dTitle }}
          <span>{{ dTitleext }}</span>
        </h2>
      </header>
      <main>
        <div class="content_web" v-for="(item, index) in list" :key="index">
          <div class="one_web" v-if="item.style == 1">
            <div class="text_one" v-html="item.content.text"></div>
            <img :src="item.content.img" />
          </div>
          <div class="one_web_bottom" v-if="item.style == 9">
            <img :src="item.content.img" />
            <div class="text_one_bottom" v-html="item.content.text"></div>
          </div>
          <div class="tow_web" v-if="item.style == 2">
            <img :src="item.content.img" alt="" />
          </div>
          <div class="three_web" v-if="item.style == 3">
            <div v-html="item.content.text" v-if="item.resource == 1 || item.resource == 0"></div>
          </div>
          <div class="four_web" v-if="item.style == 4">
            <div v-html="item.content.text"></div>
          </div>
          <div class="web_video" v-if="item.style == 5">
            <button @click="webVideo">
              查看Demo
              <van-icon name="arrow-down" />
            </button>
            <ul>
              <li v-for="videolist in item.content.videos" :key="videolist.video" :style="style" @click="ViewVideo(videolist)">
                {{ videolist.text }}
              </li>
            </ul>
            <p v-html="item.content.text"></p>
          </div>
          <div class="six_web" v-if="item.style == 6">
            <div v-html="item.content.text"></div>
          </div>
          <div class="seven_web" v-if="item.style == 7">
            <button @click="download">
              资源下载
              <van-icon name="arrow-down" />
            </button>
            <div v-html="item.content.text"></div>
          </div>
        </div>
      </main>
      <van-popup v-model="showvideoWeb" closeable>
        <video controls="false" class="xuan_video" :poster="videoImg" ontrols="controls" :src="videoUrl">
          您的浏览器不支持Video标签。
        </video>
      </van-popup>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: undefined,
      equipment: '',
      only: true,
      mask: 'NO',
      showFooter: true,
      show: null,
      infoId: '',
      list: [],
      dTitle: '',
      dTitleext: '',
      dialogVisible: false, // 弹窗层
      videoImg: '', // 视频封面
      videoUrl: '', // 视频地址
      downloadlist: new Array(), // 下载列表
      open: false,
      style: '',
      showvideoWeb: false,
      errortext: '',
      pStatus: '',
      name: '',
      CategoryStr: '',
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
    this.infoId = decodeURIComponent(this.getQueryValue('infoId'))
    if (this.infoId.indexOf('?') !== -1) {
      let arr = this.infoId.split('?')
      console.log(arr)
      console.log('包含')
      this.infoId = arr[0]
    }
    console.log(this.infoId)
    this.pStatus = this.getQueryValue('pStatus')
    this.istitle = this.getQueryValue('istitle')
    this.name = this.getQueryValue('name')
    this.CategoryStr = this.getQueryValue('CategoryStr')
    if (this.pStatus == 2) {
      this.getYuLan()
    } else {
      this.getDetail()
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
    if (from.name == 'brief') {
      if (location.href.indexOf('#reloaded') == -1) {
        location.href = location.href + '#reloaded'
        location.reload()
      }
    }
  },
  watch: {
    $route(to, form) {
      this.infoId = to.query.infoId
      if (this.infoId.indexOf('?') !== -1) {
        let arr = this.infoId.split('?')
        console.log(arr)
        console.log('包含')
        this.infoId = arr[0]
      }
      this.pStatus = to.query.pStatus
      if (this.pStatus == 2) {
        this.getYuLan()
      } else {
        this.getDetail()
      }
      this.istitle = this.getQueryValue('istitle')
      this.name = this.getQueryValue('name')
      this.CategoryStr = this.getQueryValue('CategoryStr')
      if (this.istitle == '星辰') {
        document.title = '星辰 STAR-MC1- CPU -安谋科技'
      } else if (this.istitle == '周易') {
        document.title = '周易 NPU- XPU -安谋科技'
      } else if (this.istitle == '玲珑') {
        document.title = '玲珑 i3/i5 ISP - XPU -安谋科技'
      }
      if (this.name && this.CategoryStr) {
        document.title = decodeURIComponent(this.name) + '-' + this.CategoryStr + '-安谋科技'
      }
    },
  },
  mounted() {
    if (this.istitle == '星辰') {
      document.title = '星辰 STAR-MC1- CPU -安谋科技'
    } else if (this.istitle == '周易') {
      document.title = '周易 NPU- XPU -安谋科技'
    } else if (this.istitle == '玲珑') {
      document.title = '玲珑 i3/i5 ISP - XPU -安谋科技'
    }
    if (this.name && this.CategoryStr) {
      document.title = decodeURIComponent(this.name) + '-' + this.CategoryStr + '-安谋科技'
    }
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        this.h = document.documentElement.clientHeight // 视口的高度
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
      })()
    }
  },
  methods: {
    download() {
      localStorage.setItem('download', JSON.stringify(this.downloadlist))
      this.$router.push({ path: `/download?infoId=${this.infoId}` })
    },
    // 打开视频列表web
    webVideo() {
      if (this.open) {
        this.open = false
        this.style = 'height: 0px;opacity: 0;'
      } else {
        this.open = true
        this.style = 'height: 36px;opacity: 1;'
      }
    },
    // 查看视频 web
    webOpenvideo(video) {
      this.videoImg = video.img
      this.videoUrl = video.video
      this.showvideoWeb = true
    },
    // 查看视频pc
    ViewVideo(video) {
      if (this.equipment == 'pc') {
        const { href } = this.$router.resolve({
          name: 'video',
          query: {
            infoId: this.infoId,
            text: video.text,
          },
        })
        window.open(href, '_blank')
      } else {
        this.$router.push({
          path: `/video?infoId=${this.infoId}&text=${video.text}`,
        })
      }
    },

    // 自定义列表
    async getDetail() {
      const res = await this.axios(`/v1/api/corepower_detail?infoId=${this.infoId}`)
      console.log(res)
      if (res.code == '00') {
        this.list = res.detailList
        this.dTitleext = res.dTitleext
        this.dTitle = res.dTitle
        this.downloadlist = res.detailResurce
      } else {
        this.$message.error('数据请求失败')
        this.errortext = res.msg
      }
    },
    async getYuLan() {
      const res = await this.axios(`/v1/api/corepower_detail?infoId=${this.infoId}&pStatus=${this.pStatus}`)
      if (res.code == '00') {
        this.list = res.detailList
        this.dTitleext = res.dTitleext
        this.dTitle = res.dTitle
        this.downloadlist = res.detailResurce
      } else {
        this.$message.error('数据请求失败')
        this.errortext = res.msg
      }
    },
  },
}
</script>

<style lang="less" scoped>
.mountain {
  background: #ededed;
  .pc {
    header {
      height: 5.1rem /* 565/100 */;
      padding-top: 158px;
      padding-left: 0.9rem /* 132/100 */;
      .title {
        display: flex;
        align-items: center;
        padding-top: 1.81rem /* 181/100 */;
        h2 {
          // font-size: 0.9rem;
          font-size: 80px;
          font-weight: 400;
          color: #000000;
          margin-right: 20px;
        }
        p {
          // font-size: 1.1rem;
          padding-top: 10px;
          font-size: 95px;
          font-family: arial;
          font-weight: 400;
          color: #00b3d6;
        }
      }
    }
    main {
      padding-left: 0.9rem /* 132/100 */;
      padding-right: 1.39rem /* 139/100 */;
      padding-bottom: 0.8rem /* 144/100 */;
      .content {
        .left_right {
          margin-top: 1.43rem /* 143/100 */;
          p {
            width: 9.12rem /* 912/100 */;
            float: left;
            font-size: 22px;
            line-height: 46px;
            /deep/h3 {
              margin-top: 20px;
              a {
                display: inline-block;
                width: 190px;
                height: 51px;
                background: #00b3d6;
                font-size: 24px;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #fff;
                cursor: pointer;
                text-align: center;
                line-height: 51px;
              }
            }
          }
          img {
            width: 6.92rem /* 692/100 */;
            float: right;
          }
        }
        .right_left {
          margin-top: 1.43rem /* 143/100 */;
          p {
            width: 9.12rem /* 912/100 */;
            float: right;
            font-size: 22px;
            line-height: 46px;
            /deep/h3 {
              margin-top: 20px;
              a {
                display: inline-block;
                width: 190px;
                height: 51px;
                background: #00b3d6;
                font-size: 24px;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #fff;
                cursor: pointer;
                text-align: center;
                line-height: 51px;
              }
            }
          }
          img {
            width: 6.92rem /* 692/100 */;
            float: left;
          }
        }
        .img_box {
          margin-top: 0.7rem /* 143/100 */;
        }
        .img_box_t {
          margin-top: 0;
        }
        .list_box {
          // 山海E10/E20
          margin-top: 1.46rem /* 146/100 */;
          .shanhai {
            display: flex;
            flex-wrap: wrap;
            .box_list {
              width: 50%;
              margin-bottom: 0.8rem /* 20/100 */;
              h3 {
                font-size: 0.4rem /* 40/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #00b3d6;
                line-height: 0.4rem /* 46/100 */;
                margin-bottom: 0.2rem /* 36/100 */;
              }
              h4 {
                width: 6.81rem /* 681/100 */;
                font-size: 0.22rem /* 26/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 0.4rem /* 30/100 */;
              }
              ul {
                width: 6.81rem /* 681/100 */;
                list-style-image: url('~img/Thecore/11.png');
                padding-left: 0.25rem /* 20/100 */;
                li {
                  font-size: 0.22rem /* 26/100 */;
                  font-family: Microsoft Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                  line-height: 0.45rem /* 46/100 */;
                }
              }
            }
            .top_box {
              margin-top: 0.6rem /* 30/100 */;
            }
          }
          // 山海S12
          .shanhais12 {
            display: flex;
            flex-wrap: wrap;
            .s12_box {
              width: 50%;
              margin-bottom: 0.8rem /* 20/100 */;
              h3 {
                font-size: 0.4rem /* 40/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #00b3d6;
                line-height: 0.4rem /* 46/100 */;
                margin-bottom: 0.2rem /* 36/100 */;
              }
              .rith_h3 {
                margin-top: 0.46rem /* 46/100 */;
              }
              h4 {
                width: 6.81rem /* 681/100 */;
                font-size: 0.22rem /* 26/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 0.4rem /* 30/100 */;
              }
              ul {
                width: 6.81rem /* 681/100 */;
                list-style-image: url('~img/Thecore/11.png');
                padding-left: 0.25rem /* 20/100 */;
                li {
                  font-size: 0.22rem /* 26/100 */;
                  font-family: Microsoft Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                  line-height: 0.45rem /* 46/100 */;
                }
              }
            }
          }
          // 玲珑
          .linglong {
            display: flex;
            flex-wrap: wrap;
            .long_top_box {
              width: 100%;
              margin-bottom: 1.07rem /* 107/100 */;
              h3 {
                font-size: 0.4rem /* 40/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #00b3d6;
                line-height: 0.4rem /* 46/100 */;
                margin-bottom: 0.2rem /* 36/100 */;
              }
              ul {
                // width: 6.81rem /* 681/100 */;
                list-style-image: url('~img/Thecore/11.png');
                padding-left: 0.25rem /* 20/100 */;
                li {
                  font-size: 0.22rem /* 26/100 */;
                  font-family: Microsoft Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                  line-height: 0.45rem /* 46/100 */;
                }
              }
            }
            .long_bnt {
              width: 33.33%;
              margin-bottom: 0.8rem /* 20/100 */;
              h3 {
                font-size: 0.4rem /* 40/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #00b3d6;
                line-height: 0.4rem /* 46/100 */;
                margin-bottom: 0.2rem /* 36/100 */;
              }
              .rith_h3 {
                margin-top: 0.46rem /* 46/100 */;
              }
              h4 {
                width: 6.81rem /* 681/100 */;
                font-size: 0.22rem /* 26/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 0.4rem /* 30/100 */;
              }
              ul {
                width: 6.81rem /* 681/100 */;
                list-style-image: url('~img/Thecore/11.png');
                padding-left: 0.25rem /* 20/100 */;
                li {
                  font-size: 0.22rem /* 26/100 */;
                  font-family: Microsoft Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                  line-height: 0.45rem /* 46/100 */;
                }
              }
            }
          }
          // 星晨
          .xingcheng {
            .mc1_box {
              h3 {
                font-size: 0.4rem /* 40/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #00b3d6;
                line-height: 0.4rem /* 46/100 */;
                margin-bottom: 0.2rem /* 36/100 */;
              }
              ul {
                width: 6.81rem /* 681/100 */;
                list-style-image: url('~img/Thecore/11.png');
                padding-left: 0.25rem /* 20/100 */;
                li {
                  font-size: 0.22rem /* 26/100 */;
                  font-family: Microsoft Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                  line-height: 0.45rem /* 46/100 */;
                }
              }
            }
          }
          .list {
            /deep/td {
              padding-right: 0.6rem /* 50/100 */;
              vertical-align: top;
            }
            /deep/tbody {
              tr:last-child {
                ul {
                  margin-bottom: 0;
                }
              }
            }
            /deep/tr {
              td:last-child {
                padding-right: 0rem /* 50/100 */;
              }
            }

            margin-top: 1.43rem /* 143/100 */;
            /deep/h1 {
              font-size: 0.4rem /* 40/100 */;
              font-family: Microsoft Microsoft YaHei;
              font-weight: 400;
              color: #00b3d6;
              line-height: 0.46rem /* 46/100 */;
              margin-bottom: 0.36rem /* 36/100 */;
            }
            /deep/p {
              font-size: 0.25rem /* 26/100 */;
              font-family: Microsoft Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 0.55rem /* 46/100 */;
            }
            /deep/ul {
              margin-bottom: 0.84rem /* 84/100 */;
              list-style-image: url('~img/Thecore/11.png');
              padding-left: 0.3rem /* 50/100 */;
              li {
                font-size: 0.22rem /* 26/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 0.45rem /* 46/100 */;
              }
            }
            @media (max-width: 1366px) {
              /deep/ul {
                padding-left: 0.38rem /* 50/100 */;
              }
            }
          }
        }
        .list_top {
          margin-top: 0.5rem /* 143/100 */;
          margin-bottom: 1.44rem;
          /deep/h1 {
            font-size: 0.4rem /* 40/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #00b3d6;
            line-height: 0.46rem /* 46/100 */;
            margin-bottom: 0.36rem /* 36/100 */;
          }
          /deep/p {
            font-size: 0.25rem /* 26/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.55rem /* 46/100 */;
            margin-bottom: 0.5rem;
          }
          /deep/ul {
            margin-bottom: 0.84rem /* 84/100 */;
            list-style-image: url('~img/Thecore/11.png');
            padding-left: 0.3rem /* 20/100 */;
            li {
              font-size: 0.22rem /* 26/100 */;
              font-family: Microsoft Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 0.45rem /* 46/100 */;
            }
          }
        }

        .foot_title {
          margin-top: 1rem /* 168/100 */;
          // margin-bottom: 1.44rem /* 144/100 */;
          /deep/p {
            font-size: 0.22rem /* 22/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            // color: #3e3e3e;
            line-height: 0.46rem /* 46/100 */;
            margin-bottom: 0.4rem /* 20/100 */;
          }
        }
        button {
          width: 190px;
          height: 51px;
          background: #00b3d6;
          font-size: 24px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
        .video_list {
          width: 254px;
          button {
            width: 254px;
            height: 51px;
            background: #00b3d6;
            font-size: 24px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
          }
          ul {
            li {
              width: 254px;
              height: 0px;
              background: #fff;
              text-align: center;
              line-height: 51px;
              font-size: 20px;
              font-family: Microsoft Microsoft YaHei;
              font-weight: 400;
              color: #010101;
              border-bottom: 1px solid #ededed;
              cursor: pointer;
              transition: 0.5s all;
              opacity: 0;
            }
          }
        }
        .video_list:hover {
          ul {
            li {
              height: 51px;
              opacity: 1;
            }
          }
        }
        .video_P {
          margin-top: 24px;
          font-size: 22px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 46px;
          margin-bottom: 90px;
        }
        .download {
          margin-top: 1.08rem /* 108/100 */;
          p {
            font-size: 0.22rem /* 22/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.46rem /* 46/100 */;
            margin-top: 0.24rem /* 24/100 */;
          }
        }
      }
    }
    .modal {
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: 999;
      top: 0;
      .mack {
        background: #000;
        opacity: 0.6;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
      }
      video {
        width: 60%;
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .el-icon-close {
        font-size: 0.5rem /* 50/100 */;
        position: absolute;
        z-index: 9;
        color: #fff;
        top: 30px;
        right: 30px;
        cursor: pointer;
      }
    }
  }

  .web {
    header {
      // height: 150px;
      padding-top: 80px;
      padding-left: 27px;
      // width: 80%;
      overflow: hidden;
      h2 {
        font-size: 28px;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        span {
          font-size: 28px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #00b3d6;
        }
      }
    }
    main {
      padding-left: 24px;
      padding-right: 24px;
      .content_web {
        .one_web {
          img {
            margin-top: 46px;
          }
          .text_one {
            font-size: 11px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #3e3e3e;
            line-height: 23px;
            /deep/h3 {
              margin-top: 10px;
              a {
                width: 115px;
                height: 25px;
                background: #0db2d6;
                font-size: 12px;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #fff;
                line-height: 25px;
                position: relative;
                display: inline-block;
                text-align: center;
                line-height: 25px;
              }
            }
          }
          a {
            margin-bottom: 20px;
          }
        }
        .one_web_bottom {
          img {
            margin-top: 46px;
          }
          .text_one_bottom {
            font-size: 11px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #3e3e3e;
            line-height: 23px;
            margin-top: 46px;
            /deep/h3 {
              margin-top: 10px;
              a {
                width: 115px;
                height: 25px;
                background: #0db2d6;
                font-size: 12px;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #fff;
                line-height: 25px;
                position: relative;
                display: inline-block;
                text-align: center;
                line-height: 25px;
              }
            }
          }
        }
        .tow_web {
          margin-top: 48px;
        }
        .three_web {
          margin-top: 36px;
          /deep/h1 {
            font-size: 17px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #0db2d6;
            line-height: 23px;
            margin-bottom: 10px;
            margin-top: 43px;
          }
          /deep/p {
            font-size: 13px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 23px;
          }
          /deep/span {
            line-height: 44px;
          }
          /deep/ul {
            // list-style-image: url("~img/Thecore/11.png");
            // padding-left: 20px;
            li {
              font-family: Microsoft Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              font-size: 11px;
              line-height: 23px;
              list-style-type: none;
              background: url('~img/Thecore/11.png') no-repeat 0rem 0.3rem;
              background-size: 14px 10px;
              padding-left: 20px;
            }
          }
        }
        .four_web {
          margin-top: 43px;
          /deep/h1 {
            font-size: 17px;
            font-family: Arial;
            font-weight: 400;
            color: #0db2d6;
          }
          /deep/p {
            font-size: 12px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #3e3e3e;
            line-height: 24px;
          }
        }
        .web_video {
          margin-top: 50px;
          button {
            width: 100%;
            height: 36px;
            background: #0db2d6;
            color: #fff;
            position: relative;
          }
          .van-icon {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translate(0, -50%);
          }
          ul {
            li {
              height: 0;
              text-align: center;
              line-height: 36px;
              background: #fff;
              border-bottom: 1px solid #ededed;
              opacity: 0;
              transition: 0.5s all;
            }
          }
          p {
            font-size: 12px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-top: 20px;
          }
        }
        .six_web {
          margin-top: 45px;
          /deep/h1 {
            font-size: 16px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #0db2d6;
          }
          /deep/p {
            font-size: 11px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 23px;
            margin-top: 21px;
          }
          /deep/span {
            line-height: 44px;
          }
        }
        .seven_web {
          margin-top: 45px;
          button {
            width: 115px;
            height: 25px;
            background: #0db2d6;
            font-size: 12px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 25px;
            position: relative;
            .van-icon {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translate(0, -50%);
            }
          }
          /deep/p {
            font-size: 11px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-top: 9px;
          }
        }
      }
    }
    .van-popup {
      width: 100%;
      background: transparent;
      video {
        width: 100%;
      }
    }
    .foot {
      position: relative;
      background-color: #000;
    }
  }
}
</style>
