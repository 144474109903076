<template>
  <div class="info_details">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" :mask="mask" />
      <header>
        <h3>{{ infor.iTitle }}</h3>
        <p>发布时间：{{ infor.iDate }}</p>
        <h4>{{errortext}}</h4>
      </header>
      <main>
        <div class="content" v-html="infor.content"></div>
        <!-- 资讯的底部 -->
        <div class="footer_pc" v-if="id">
          <div class="text">
            <span @click="front" v-if="preId">上一篇</span>
            <span v-if="preId && nextId">|</span>
            <span @click="behind" v-if="nextId">下一篇</span>
            <span class="return_s" @click="back">返回列表</span>
          </div>
        </div>
        <!-- 活动的底部 -->
        <div class="footer_pc" v-if="newsid">
          <div class="text">
            <span @click="newfront" v-if="preId">上一篇</span>
            <span v-if="preId && nextId">|</span>
            <span @click="newbehind" v-if="nextId">下一篇</span>
            <span class="return_s" @click="back">返回列表</span>
          </div>
        </div>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav :mask="mask" />
      <header>
        <h2>{{ infor.iTitle }}</h2>
        <p>发布时间 : {{ infor.iDate }}</p>
      </header>
      <div class="web_content" v-html="infor.content"></div>
      <div class="footer_web" v-if="id">
        <div class="text">
          <span @click="front" v-if="preId">上一篇</span>
          <span v-if="preId && nextId">|</span>
          <span @click="behind" v-if="nextId">下一篇</span>
          <span class="return_s" @click="back">返回列表</span>
        </div>
      </div>
      <div class="footer_web" v-if="newsid">
        <div class="text">
          <span @click="newfront" v-if="preId">上一篇</span>
          <span v-if="preId && nextId">|</span>
          <span @click="newbehind" v-if="nextId">下一篇</span>
          <span class="return_s" @click="back">返回列表</span>
        </div>
      </div>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>


<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: undefined,
      equipment: "",
      only: true,
      mask: "NO",
      showFooter: true,
      id: "", // 资讯id
      infor: {},
      newsid: "", // 活动id,
      nextId: "", // 下一页id
      preId: "", // 上一页id
      istop: "",
      istitle: "",
      name: "",
      pStatus: "",
      errortext: "",
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    this.id = this.getQueryValue("id");
    this.newsid = this.getQueryValue("newsid");
    this.istop = this.getQueryValue("istop");
    this.istitle = this.getQueryValue("istitle");
    this.name = this.getQueryValue("name");
    this.pStatus = this.getQueryValue("pStatus");
    console.log(this.pStatus);
    document.title = this.name + "-" + this.istitle + "-安谋科技";
    if (this.istop) {
      this.istop = 0;
    } else {
      this.istop = 1;
    }
    //  资讯详情
    if (this.id) {
      this.getinformation();
    }
    // 活动详情
    if (this.newsid) {
      this.getNewsDetail();
    }
  },
  watch: {
    $route(to, form) {
      this.id = to.query.id;
      this.newsid = to.query.newsid;
      if (this.id) {
        this.istop = 1;
        this.getinformation();
      } else {
        this.istop = 1;
        this.getNewsDetail();
      }
    },
  },
  mounted() {
    document.title = this.name + "-" + this.istitle + "-安谋科技";
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        this.h = document.documentElement.clientHeight; // 视口的高度
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {
    front() {
      this.getNextdetail(1);
    },
    behind() {
      this.getNextdetail(2);
    },
    back() {
      //infolist
      // this.$router.go(-1); //返回上一层
      if (this.id) {
        this.$router.push({ path: "/infolist" });
      } else if (this.newsid) {
        this.$router.push({ path: "/activitylist" });
      }
    },
    newfront() {
      this.nextdetail(1);
    },
    newbehind() {
      this.nextdetail(2);
    },
    // 活动翻页接口  弃用
    // async nextdetail(val) {
    //   const res = await this.axios.get(
    //     `/v1/api/get_news_nextdetail?newsId=${this.newsid}&next=${val}`
    //   );
    //   if (res.code == "00") {
    //     this.$router.push({ path: `infodetails?id=${res.data.newsId}` });
    //   } else {
    //     this.$message.error("数据请求失败");
    //   }
    // },
    nextdetail(val) {
      if (val == 1) {
        this.$router.push({ path: `infodetails?id=${this.preId}` });
      } else if (val == 2) {
        this.$router.push({ path: `infodetails?id=${this.nextId}` });
      }
    },
    //活动详情
    async getNewsDetail() {
      const res = await this.axios.get(
        `/v1/api/get_news_detail?newsId=${this.newsid}`
      );
      if (res.code == "00") {
        this.infor = res.data.detail;
        this.nextId = res.data.nextId;
        this.preId = res.data.preId;
        this.name = res.data.detail.iTitle;
        document.title = this.name + "-" + this.istitle + "-安谋科技";
        let iframe = document.createElement("iframe");
        iframe.src = require("../../../assets/img/Armip/02.png");
        iframe.style.display = "none";
        let fn = function () {
          setTimeout(function () {
            iframe.removeEventListener("load", fn);
            document.body.removeChild(iframe);
          }, 0);
        };
        iframe.addEventListener("load", fn);
        document.body.appendChild(iframe);
      } else {
        this.$message.error("数据请求失败");
        this.errortext = "当前信息暂时无法查看，请稍后再试。";
      }
    },
    // 资讯翻页
    async getNextdetail(val) {
      console.log(this.nextId);
      if (val == 1) {
        this.$router.push({ path: `infodetails?id=${this.preId}` });
      } else if (val == 2) {
        this.$router.push({ path: `infodetails?id=${this.nextId}` });
      }
      // const res = await this.axios.get(
      //   `/v1/api/get_information_nextdetail?informationId=${
      //     this.id
      //   }&next=${val}`
      // );
      // if (res.code == "00") {
      //   // this.infor = res.data;
      //   // this.id = res.data.informationId;
      //   this.istop = 1;
      //   this.$router.push({ path: `infodetails?id=${res.data.informationId}` });
      // } else {
      //   this.$message.error("数据请求失败");
      // }
    },
    // 资讯详情
    async getinformation() {
      const res = await this.axios(
        `/v1/api/get_information_detail?informationId=${this.id}&isTop=${this.istop}&pStatus=${this.pStatus}`
      );
      if (res.code == "00") {
        console.log(res);
        this.infor = res.data.detail;
        this.name = res.data.detail.iTitle;
        this.nextId = res.data.nextId;
        this.preId = res.data.preId;
        document.title = this.name + "-" + this.istitle + "-安谋科技";
        let iframe = document.createElement("iframe");
        iframe.src = require("../../../assets/img/Armip/02.png");
        iframe.style.display = "none";
        let fn = function () {
          setTimeout(function () {
            iframe.removeEventListener("load", fn);
            document.body.removeChild(iframe);
          }, 0);
        };
        iframe.addEventListener("load", fn);
        document.body.appendChild(iframe);
      } else {
        this.$message.error("数据请求失败");
        this.errortext = "当前信息暂时无法查看，请稍后再试。";
      }
    },
  },
};
</script>




<style lang="less" scoped>
.info_details {
  .pc {
    header {
      height: 4.5rem /* 497/100 */;
      padding-top: 2.65rem /* 265/100 */;
      padding-left: 0.82rem /* 82/100 */;
      h3 {
        font-size: 0.5rem /* 60/100 */;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-bottom: 0.1rem /* 20/100 */;
      }
      p {
        font-size: 0.18rem /* 18/100 */;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #565656;
      }
    }
    main {
      .content {
        padding-left: 0.82rem /* 82/100 */;
        padding-right: 0.82rem /* 82/100 */;
        /deep/h3 {
          font-size: 30px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          margin-bottom: 30px;
          margin-top: 38px;
        }
        /deep/p {
          font-size: 14px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 31px;
          img {
            display: inline;
            max-width: 700px;
          }
        }
        /deep/ul {
          list-style: disc;
          padding-left: 0.6rem /* 20/100 */;
          margin-top: 0.1rem /* 10/100 */;
          margin-bottom: 0.1rem /* 10/100 */;
          li {
            font-size: 14px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 31px;
          }
        }
      }
      .footer_pc {
        height: 2.5rem /* 250/100 */;
        padding-right: 0.8rem /* 80/100 */;
        .text {
          float: right;
          margin-top: 0.99rem /* 99/100 */;
          font-size: 14px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 300;
          color: #010101;
          span {
            margin-right: 0.15rem /* 15/100 */;
            cursor: pointer;
          }
          .return_s {
            padding-left: 0.15rem /* 15/100 */;
          }
        }
      }
    }
  }
  .web {
    background: #ededed;
    header {
      padding-top: 99px;
      padding-left: 25px;
      padding-right: 23px;
      h2 {
        font-size: 25px;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
      }
      p {
        font-size: 12px;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #565656;
        margin-top: 16px;
      }
    }
    .web_content {
      padding-left: 25px;
      padding-right: 23px;
      padding-top: 23px;
      font-size: 14px;
      line-height: 23px;
      /deep/h3 {
        font-size: 18px;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 23px;
      }
      /deep/img {
        display: inline;
        max-width: 100%;
      }
      /deep/ul {
        list-style: disc;
        padding-left: 20px /* 20/100 */;
        margin-top: 10px /* 10/100 */;
        margin-bottom: 10px /* 10/100 */;
        li {
          font-size: 14px;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 31px;
        }
      }
      // /deep/p {
      //   font-size: 14px;
      //   font-family: Microsoft Microsoft YaHei;
      //   font-weight: 400;
      //   color: #000000;
      //   line-height: 23px;
      //   margin-top: 10px;
      // }
    }
    .footer_web {
      // padding-right: 0.8rem /* 80/100 */;
      text-align: center;
      .text {
        margin-top: 50px;
        font-size: 13px;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 300;
        color: #010101;
        span {
          margin-right: 15px;
          cursor: pointer;
        }
        .return_s {
          padding-left: 10px;
        }
      }
    }
    .foot {
      position: relative;
      background-color: #000;
    }
  }
}
</style>