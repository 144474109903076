<template>
  <div class="contact">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <img src="~img/ContactUs/01.png" class="top_img">
      <img src="~img/ContactUs/11.png" class="icon_img">
      <main>
        <ul>
          <li>
            <img src="~img/ContactUs/02.png" class="conten_img">
            <div class="text">
              <h2>深圳</h2>
              <p>深圳市南山区科发路19号华润置地大厦D栋24层</p>
              <p>Tower D, China Resources Land, No.19 Kefa Road, Nanshan District, Shenzhen, PRC </p>
              <p>Postcode: 518052</p>
              <div class="box">
                <div class="Contactno">
                  <img src="~img/ContactUs/06.png" class="icon_img_on">
                  <img src="~img/ContactUs/07.png" class="icon_img_tow">
                  <span>Tel</span>
                  <span>+860755-21912761</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <img src="~img/ContactUs/03.png" class="conten_img">
            <div class="text">
              <h2>上海</h2>
              <p>上海市闵行区田林路1016号科技绿洲三期11号楼（近合川路）</p>
              <p>Building 11, Shanghai Business Park III No. 1016 Tianlin Road Minhang District Shanghai, PRC</p>
              <p>Postcode: 200233</p>
              <div class="box">
                <div class="Contactno">
                  <img src="~img/ContactUs/06.png" class="icon_img_on">
                  <img src="~img/ContactUs/07.png" class="icon_img_tow">
                  <span>Tel</span>
                  <span>+8621-5456 8500</span>
                </div>
                <div class="Contactno">
                  <img src="~img/ContactUs/08.png" class="icon_img_on">
                  <img src="~img/ContactUs/09.png" class="icon_img_tow">
                  <span>Fax</span>
                  <span>+8621-5456 8501</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <img src="~img/ContactUs/04.png" class="conten_img">
            <div class="text">
              <h2>北京</h2>
              <p>北京市海淀区北四环西路58号理想国际大厦913-917室</p>
              <p>Room 913-917, Ideal Plaza, 58 West Road, North 4th Ring Road Haidian District, Beijing, PRC</p>
              <p>Postcode: 100080</p>
              <div class="box">
                <div class="Contactno">
                  <img src="~img/ContactUs/06.png" class="icon_img_on">
                  <img src="~img/ContactUs/07.png" class="icon_img_tow">
                  <span>Tel</span>
                  <span>+8610-5081 3303</span>
                </div>
                <div class="Contactno">
                  <img src="~img/ContactUs/08.png" class="icon_img_on">
                  <img src="~img/ContactUs/09.png" class="icon_img_tow">
                  <span>Fax</span>
                  <span>+8610-8217 2010</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <img src="~img/ContactUs/05.png" class="conten_img">
            <div class="text">
              <h2>成都</h2>
              <p>四川省成都市天府新区湖畔路西段99号D区B5栋19层</p>
              <p>Floor 19,Building B5,District D,No.99,West Hupan Rd,Tianfu New Area Chengdu,Sichuan,PRC</p>
              <p>Postcode: 610000</p>
            </div>
            <div class="box">
              <div class="Contactno">
                <img src="~img/ContactUs/06.png" class="icon_img_on">
                <img src="~img/ContactUs/07.png" class="icon_img_tow">
                <span>Tel</span>
                <span>+8628-6872 1200</span>
              </div>

            </div>
          </li>
        </ul>
        <div class="youxiang">
          <img src="~img/ContactUs/10.png" class="email">
          <p>联络邮箱：<a href="mailto:Info@armchina.com"> Info@armchina.com</a></p>
        </div>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <img src="~img/ContactUs/12.png" class="web_top_img">
      <img src="~img/ContactUs/27.jpg" class="web_icon_img">
      <main>
        <ul>
          <li>
            <img src="~img/ContactUs/02.png" class="xiaogo">
            <h2>深圳</h2>
            <p>深圳市南山区科发路19号华润置地大厦D栋24层</p>
            <p>Tower D, China Resources Land, No.19 Kefa Road, Nanshan District, Shenzhen, PRC</p>
            <p>Postcode: 518052</p>
            <div class="box">
              <div class="web_contact">
                <img src="~img/ContactUs/13.png" class="icon_img_on">
                <span>Tel</span>
                <span>+860755-21912761</span>
              </div>
            </div>
          </li>
          <li>
            <img src="~img/ContactUs/03.png" class="xiaogo">
            <h2>上海</h2>
            <p>上海市闵行区田林路1016号科技绿洲三期11号楼（近合川路）</p>
            <p>Building 11, Shanghai Business Park III No. 1016 Tianlin Road Minhang District Shanghai, PRC
              <p>
                <p>Postcode: 200233</p>
                <div class="box">
                  <div class="web_contact">
                    <img src="~img/ContactUs/13.png" class="icon_img_on">
                    <span>Tel</span>
                    <span>+8621-5456 8500</span>
                  </div>
                  <div class="web_contact">
                    <img src="~img/ContactUs/14.png" class="icon_img_on">
                    <span>Fax</span>
                    <span>+8621-5456 8501</span>
                  </div>
                </div>
          </li>
          <li>
            <img src="~img/ContactUs/04.png" class="xiaogo">
            <h2>北京</h2>
            <p>北京市海淀区北四环西路58号理想国际大厦913-917室</p>
            <p>Room 913-917, Ideal Plaza, 58 West Road, North 4th Ring Road Haidian District, Beijing, PRC
              <p>
                <p>Postcode: 100080</p>
                <div class="box">
                  <div class="web_contact">
                    <img src="~img/ContactUs/13.png" class="icon_img_on">
                    <span>Tel</span>
                    <span>+8610-5081 3303</span>
                  </div>
                  <div class="web_contact">
                    <img src="~img/ContactUs/14.png" class="icon_img_on">
                    <span>Fax</span>
                    <span>+8610-8217 2010</span>
                  </div>
                </div>
          </li>
          <li>
            <img src="~img/ContactUs/05.png" class="xiaogo">
            <h2>成都</h2>
            <p>四川省成都市天府新区湖畔路西段99号D区B5栋19层</p>
            <p>Floor 19,Building B5,District D,No.99,West Hupan Rd,Tianfu New Area Chengdu,Sichuan,PRC</p>
            <p>Postcode: 610000</p>
            <div class="box">
              <div class="web_contact">
                <img src="~img/ContactUs/13.png" class="icon_img_on">
                <span>Tel</span>
                <span>+8628-6872 1200</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="web_you">
          <img src="~img/ContactUs/15.png">
          <p>联络邮箱：<a href="mailto:Info@armchina.com"> Info@armchina.com</a></p>
        </div>
      </main>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>
<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up
  },
  data() {
    return {
      w: "",
      equipment: "",
      only: true,
      showFooter: true
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    console.log(this.equipment);
    document.title = "联系我们 - 安谋科技";
  },
  mounted() {
    document.title = "联系我们 - 安谋科技";

    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {}
};
</script>


<style lang="less" scoped>
.contact {
  background: #000;
  .pc {
    position: relative;
    // padding-top: 158px;
    .top_img {
      opacity: 0.7;
      position: absolute;
      top: 0;
    }
    .icon_img {
      width: 0.38rem /* 38/100 */;
      position: absolute;
      top: 2.38rem /* 238/100 */;
      right: 0.75rem /* 75/100 */;
    }
    main {
      padding-top: 2.58rem /* 258/100 */;
      margin-right: 1.39rem /* 139/100 */;
      margin-left: 1.39rem /* 139/100 */;
      position: relative;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 7.62rem /* 762/100 */;
          height: 6.84rem /* 684/100 */;
          margin-bottom: 0.8rem /* 80/100 */;
          h2 {
            font-size: 0.4rem /* 40/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.4rem /* 40/100 */;
            margin-top: 0.47rem /* 47/100 */;
            margin-bottom: 0.44rem /* 44/100 */;
            transition: 0.5s all;
          }
          p {
            font-size: 0.16rem /* 16/100 */;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #8a8a8a;
            line-height: 0.25rem; /* 25/100 */
            width: 6.46rem /* 646/100 */;
          }
          .box {
            margin-top: 0.35rem /* 35/100 */;
            .Contactno {
              height: 0.21rem /* 21/100 */;
              margin-bottom: 0.21rem /* 21/100 */;
              .icon_img_on {
                width: 0.21rem /* 21/100 */;
                float: left;
                margin-right: 0.1rem /* 10/100 */;
                display: none;
                transition: 0.5s all;
              }
              .icon_img_tow {
                width: 0.21rem /* 21/100 */;
                float: left;
                margin-right: 0.1rem /* 10/100 */;
                transition: 0.5s all;
              }
              span {
                margin-right: 0.18rem /* 20/100 */;
                font-size: 0.16rem /* 16/100 */;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.21rem /* 21/100 */;
              }
            }
          }
        }
        li:hover {
          p {
            color: #fff;
          }
          h2 {
            color: #00b6da;
          }
          .Contactno {
            .icon_img_on {
              display: block;
            }
            .icon_img_tow {
              display: none;
            }
          }
        }
      }
      .youxiang {
        height: 1.76rem /* 176/100 */;
        border-top: 1px solid #4d4d50;
        padding-top: 0.27rem /* 27/100 */;
        .email {
          width: 0.23rem /* 23/100 */;
          float: left;
          margin-right: 0.13rem /* 13/100 */;
        }
        p {
          font-size: 0.2rem /* 20/100 */;
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #8a8a8a;
          line-height: 0.23rem;
        }
        a {
          cursor: pointer;
        }
        a:hover {
          color: #fff;
        }
      }
    }
  }
  .web {
    position: relative;
    background: #000;
    .web_top_img {
      position: absolute;
      top: 0;
      opacity: 0.2;
    }
    .web_icon_img {
      width: 139px;
      position: absolute;
      top: 87px;
      right: 25px;
      z-index: 99;
    }
    main {
      margin-right: 25px;
      margin-left: 25px;
      padding-top: 152px;
      // background: orange;
      position: relative;
      ul {
        li {
          margin-bottom: 46px;
          h2 {
            font-size: 20px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
            margin-top: 24px;
            margin-bottom: 22px;
          }
          p {
            font-size: 12px;
            font-family: Microsoft Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
          }
          .box {
            margin-top: 20px;
            .web_contact {
              height: 15px;
              margin-bottom: 15px;
              .icon_img_on {
                width: 15px;
                float: left;
                margin-top: 2px;
                margin-right: 10px;
              }
              span {
                font-size: 12px;
                font-family: Microsoft Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .web_you {
        height: 84px;
        border-top: 1px solid #4d4d50;
        padding-top: 29px;
        img {
          width: 17px;
          float: left;
          margin-right: 9px;
        }
        p {
          font-family: Microsoft Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .foot {
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>
