<template>
  <div class="mounlist">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <div v-for="(item, index) in list" :key="index">
        <h2>{{ item.fullName }}</h2>
        <div class="box clearfix">
          <div class="pc_box" @click="Mountion(item, val)" v-for="(val, index) in item.productInfos" :key="index">
            <!-- :style="`background: url(${val.productExt}) `" -->
            <img :src="val.productExt" class="bg_img" />
            <div class="content">
              <h3>{{ val.productName }}</h3>
              <p>{{ val.productDesc }}</p>
              <div v-if='val.productMark'>
                <p class='p2' v-for='(text,index) in val.productMark' :key='index'>{{text}}</p>
              </div>
              <div class="pc_fenge"></div>
              <img src="~img/Thecore/04.png" />
            </div>
          </div>
        </div>
      </div>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <div v-for="(item, index) in list" :key="index">
        <h2>{{ item.fullName }}</h2>
        <div class="web_box" v-for="(val, index) in item.productInfos" :key="index" @click="Mountion(item, val)">
          <img :src="val.productExt" class="web_bgimg">
          <div class="text_cont">
            <h3>{{ val.productName }}</h3>
            <p>{{ val.productDesc }}</p>
            <div class='p2' v-if='val.productMark'>
              <p v-for='(text,index) in val.productMark' :key='index'>{{text}}</p>
            </div>
          </div>
          <div class="web_fenge"></div>
          <img src="~img/Thecore/04.png" />
        </div>
      </div>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>


<script>
import $ from "jquery";
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up
  },
  data() {
    return {
      w: undefined,
      h: undefined,
      equipment: "",
      only: true,
      showFooter: true,
      dataId: "",
      list: "",
      pStatus: ''
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    this.h = document.documentElement.clientHeight; // 视口的高度
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    this.dataId = this.getQueryValue("dataId");
    this.pStatus = this.getQueryValue('pStatus')
    this.getCorepo();
  },
  beforeRouteEnter(to, from, next) {
    next();
    if (from.name == "brief") {
      if (location.href.indexOf("#reloaded") == -1) {
        location.href = location.href + "#reloaded";
        location.reload();
      }
    }
  },
  mounted() {
    $("body").css("cursor", "default");
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        this.h = document.documentElement.clientHeight; // 视口的高度
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  watch: {
    $route(to, form) {
      console.log(to.query.dataId);
      this.dataId = to.query.dataId;
      this.getCorepo();
    }
  },
  methods: {
    Mountion(item, val) {
      console.log(val, item);
      this.$router.push({
        path: `/mountain?infoId=${val.infoId}&name=${val.productName}&CategoryStr=${item.productCategoryStr}&pStatus=${this.pStatus}`
      });
    },
    async getCorepo() {
      const res = await this.axios.get(
        `/v1/api/corepower_infos?dataId=${this.dataId}&pStatus=${this.pStatus}`
      );
      console.log(res);
      if (res.code == "00") {
        this.list = res.data;
        console.log(this.list);
        this.$nextTick(() => {
          document.title =
            this.list[0].fullName +
            "-" +
            this.list[0].productSeriesStr +
            "-" +
            this.list[0].productLineStr +
            "-安谋科技";
        });
        let iframe = document.createElement("iframe");
        iframe.src = require("../../assets/img/Armip/02.png");
        iframe.style.display = "none";
        let fn = function () {
          setTimeout(function () {
            iframe.removeEventListener("load", fn);
            document.body.removeChild(iframe);
            console.log("title", document.title);
          }, 0);
        };
        iframe.addEventListener("load", fn);
        document.body.appendChild(iframe);
      } else {
        this.$message.error("数据请求失败");
      }
    }
  }
};
</script>


<style lang="less" scoped>
.mounlist {
  background: #000;
  overflow: hidden;
  .pc {
    background: url("~img/Thecore/01.png") no-repeat;
    background-size: contain;
    border-top: 1px solid;
    h2 {
      font-size: 0.6rem /* 90/100 */;
      font-weight: 400;
      color: #fff;
      margin-top: 313px;
      margin-left: 0.8rem /* 113/100 */;
      // font-family: Verdana, "Microsoft Microsoft YaHei", "宋体", Tahoma, Arial, sans-serif;
    }
    .box {
      margin-top: 190px;
      margin-left: 0.8rem;
      display: flex;
      flex-wrap: wrap;
    }
    .pc_box {
      overflow: hidden;
      width: 7.79rem /* 779/100 */;
      height: 4.43rem /* 443/100 */;
      // background: url("~img/Thecore/02.png") no-repeat;
      // background-size: 100% 100%;
      margin-right: 1.3rem;
      // float: left;
      padding-top: 0.55rem /* 55/100 */;
      padding-left: 0.66rem /* 66/100 */;
      position: relative;
      margin-bottom: 167px /* 167/100 */;
      cursor: pointer;
      overflow: hidden;
      .bg_img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: 1.5s all;
      }

      .content {
        position: relative;
        height: 100%;
        h3 {
          font-size: 0.6rem /* 60/100 */;
          font-weight: 400;
          color: #eaeaea;
          line-height: 0.6rem;
          width: 82%;
        }
        p {
          font-size: 0.28rem /* 28/100 */;
          font-weight: 400;
          font-family: Arial;
          color: #00b3d6;
          margin-top: 0.2rem /* 22/100 */;
        }
        .p2 {
          color: #fff;
          position: absolute;
          bottom: 0.5rem;
          // margin-top: 1.6rem /* 206/100 */;
        }
        img {
          position: absolute;
          width: 0.57rem /* 57/100 */;
          top: 0rem /* 53/100 */;
          right: 0.4rem /* 40/100 */;
        }
      }

      .pc_fenge {
        width: 6.73rem /* 673/100 */;
        height: 4px;
        background: #eaeaea;
        position: absolute;
        bottom: 0.3rem;
      }
    }
    .pc_box:hover {
      .bg_img {
        transform: scale(1.3);
      }
    }
    .pc_box2 {
      width: 7.79rem /* 779/100 */;
      height: 4.43rem /* 443/100 */;
      background: url("~img/Thecore/03.png") no-repeat;
      background-size: 100% 100%;
      float: right;
      margin-right: 1.39rem /* 139/100 */;
      padding-top: 0.55rem /* 55/100 */;
      padding-left: 0.66rem /* 66/100 */;
      position: relative;
      margin-bottom: 167px /* 167/100 */;
      h3 {
        font-size: 0.6rem /* 60/100 */;
        font-weight: 400;
        color: #eaeaea;
      }
      p {
        font-size: 0.28rem /* 28/100 */;
        font-weight: 400;
        font-family: Arial;
        color: #00b3d6;
        margin-top: 0.22rem /* 22/100 */;
      }
      img {
        position: absolute;
        width: 0.57rem /* 57/100 */;
        top: 0.53rem /* 53/100 */;
        right: 0.4rem /* 40/100 */;
      }
      .pc_fenge {
        width: 6.73rem /* 673/100 */;
        height: 4px;
        background: #eaeaea;
        margin-top: 1.86rem /* 206/100 */;
      }
    }
  }
  .web {
    background: url("~img/Thecore/05.png") no-repeat;
    background-size: contain;
    border-top: 1px solid;
    position: relative;
    h2 {
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 112.5px;
      margin-left: 26.5px;
      // font-family: Verdana, "Microsoft Microsoft YaHei", "宋体", Tahoma, Arial, sans-serif;
    }
    .web_box {
      height: 180.5px;
      width: 324.5px;
      // background: url("~img/Thecore/02.png") no-repeat;
      background-size: 100% 100%;
      margin: 36px;
      margin-left: 26px;
      padding-left: 27px;
      padding-top: 23px;
      position: relative;
      img {
        width: 24px;
        position: absolute;
        top: 22px;
        right: 16.5px;
      }
      .web_bgimg {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
      }
      .text_cont {
        position: relative;
      }
      h3 {
        font-size: 25px;
        width: 82%;
        font-weight: 400;
        color: #eaeaea;
      }
      p {
        font-size: 12px;
        font-family: Arial;
        font-weight: 400;
        color: #50b0d2;
        margin-top: 10px;
      }
      .p2 {
        margin-top: 50px;
        p {
          color: #fff;
        }
      }
      .web_fenge {
        width: 280.35px;
        height: 2px;
        background: #eaeaea;
        // margin-top: 75px;
        position: absolute;
        bottom: 16px;
      }
    }
    .box2 {
      background: url("~img/Thecore/03.png") no-repeat;
      background-size: 100% 100%;
    }
    .foot {
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>

